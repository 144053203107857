/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useState } from "react";
import { MDBDataTableV5 } from "mdbreact";
import { productsController } from "../../../../api/products";
import { showNotification } from "../../../../utils/showNotification";
import Loader from "../../../Loader/Loader";
import { useNavigate } from "react-router-dom";

function ListProduct() {
  const history = useNavigate();

  const [loading, setLoading] = useState(false);
  const [products, setProducts] = useState({
    columns: [
      {
        label: "Code",
        field: "product_code",
      },
      {
        label: "Name",
        field: "product_name",
      },
      {
        label: "Price",
        field: "unit_price",
      },
      {
        label: "Discount",
        field: "discount",
      },
      {
        label: "GST",
        field: "gst",
      },
      {
        label: "Category",
        field: "category_name",
      },
      {
        label: "Action",
        field: "action",
      },
    ],
    rows: [],
  });

  const navigateTo = (page, id) => {
    if (page === "add") {
      history("/admin/products/add");
    } else if (page === "edit") {
      history(`/admin/products/${id}/edit`);
    }
  };

  useEffect(async () => {
    try {
      setLoading(true);
      const result = await productsController.getAllProducts();
      if (result.success) {
        setProducts({
          ...products,
          rows: result.productsList.map((prod) => {
            return {
              ...prod,
              unit_price: prod.unit_price.toFixed(2),
              discount: prod.discount >= 0 ? prod.discount + "%" : "-",
              gst: prod.gst >= 0 ? prod.gst + "%" : "-",
              category: prod.category || "-",
              action: (
                <>
                  <span
                    className="me-3"
                    onClick={() => {
                      navigateTo("edit", prod.id);
                    }}
                  >
                    <i className="icons bi bi-pencil-square"></i>
                  </span>
                </>
              ),
            };
          }),
        });
      }
    } catch (error) {
      showNotification(error.message, "error");
    } finally {
      setLoading(false);
    }
  }, []);

  return (
    <div className="container mt-5">
      {loading && <Loader />}
      <h2>PRODUCTS LIST</h2>
      <div className="mt-3 d-flex justify-content-end">
        <button className="btn btn-primary" onClick={() => navigateTo("add")}>
          Add Product
        </button>
      </div>
      <div className="mt-4">
        {!loading && (
          <MDBDataTableV5
            data={products}
            searching={false}
            entriesOptions={[8, 16, 24, 40]}
            entries={8}
            noBottomColumns={true}
            noRecordsFoundLabel={"No Products Found"}
            responsive
            sortable={false}
          />
        )}
      </div>
    </div>
  );
}

export default ListProduct;
