import React, { useEffect, useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { categoryController } from "../../../../api/admin/category";
import { showNotification } from "../../../../utils/showNotification";
import Loader from "../../../Loader/Loader";

function CategoryModal({
  show,
  handleClose,
  title,
  data,
  setData,
  refresh,
  setRefresh,
}) {
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const handleSubmit = async () => {
    try {
      setLoading(true);
      const res = await categoryController.handleCategoryActions(data, title);
      if (res.success) {
        showNotification(res.message, "success");
        handleClose();
      } else {
        showNotification(res.message, "error");
      }
    } catch (err) {
      showNotification(err.message, "error");
    } finally {
      setLoading(false);
      setRefresh(!refresh);
    }
  };

  useEffect(() => {
    setError("");
  }, [show]);

  return (
    <Modal show={show} onHide={handleClose} backdrop="static">
      {loading && <Loader />}
      <Modal.Header closeButton className="px-3 py-2">
        <Modal.Title>{`${title} Category`}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {title === "Delete" && (
          <p>
            Are you sure to delete the Category - <b>{data.label}</b> ?
          </p>
        )}
        {!(title === "Delete") && (
          <Form>
            <div>
              <label htmlFor="brand" className="form-label">
                Category Name*
              </label>
              <input
                type="text"
                className="form-control"
                id="category"
                autoComplete="off"
                value={data.label ? data.label : ""}
                onChange={(e) => {
                  if (e.target.value.length > 30) {
                    setError("Category length must be 30 or less");
                  } else {
                    setError("");
                  }
                  setData({ ...data, label: e.target.value || "" });
                }}
                onBlur={(e) => {
                  if (!e.target.value) {
                    setError("*Category Name is required");
                  }
                }}
                onKeyDown={(e) => {
                  if (e.key.length === 1) {
                    let ascii = e.key.charCodeAt();
                    if (
                      !(
                        (ascii >= 65 && ascii <= 90) ||
                        (ascii >= 97 && ascii <= 122) ||
                        ascii === 32
                      )
                    ) {
                      e.preventDefault();
                    }
                  }
                }}
              />
              {error ? <p className="mt-2 mb-0 text-danger">{error}</p> : <></>}
            </div>
          </Form>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="primary"
          disabled={!data.label || error}
          onClick={handleSubmit}
        >
          {title}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default CategoryModal;
