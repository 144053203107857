/*eslint-disable react-hooks/exhaustive-deps*/
/*eslint-disable no-unused-vars*/

import React, { useEffect, useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import "./LoginModal.css";
import Google from "../../assets/icons/google-icon.svg";
import { loginController } from "../../api/login";
import { showNotification } from "../../utils/showNotification";
import { useGoogleLogin } from "@react-oauth/google";
import Loader from "../Loader/Loader";
import { useFormik } from "formik";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";

function LoginModal({ show, setShow, refresh, setRefresh, page }) {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});
  const [modalTitle, setModalTitle] = useState("Sign In");
  const [otpInputView, setOtpInputView] = useState(false);
  const [errors, setErrors] = useState({});

  const history = useNavigate();

  const handleClose = () => {
    setShow(false);
  };

  const signInValues = {
    mobile: "",
    otp: "",
  };

  const handleSignIn = async () => {
    try {
      const login = await loginController.signIn({
        method: "mobile",
        data: { mobile: data?.mobile },
      });
      if (login.success) {
        setOtpInputView(true);
        showNotification(login.message, "success");
      } else {
        showNotification(login.message, "error");
      }
    } catch (error) {
      showNotification(error, "error");
    }
  };

  const onSuccess = async (res) => {
    try {
      setLoading(true);
      const result = await loginController.signIn({
        code: res.code,
        method: "google",
      });

      if (result.success) {
        localStorage.setItem("meruwell_token", result.data.token);
        setRefresh(!refresh);
        showNotification(result.message, "success");
        handleClose();

        if (page === "plan") {
          history("/plan/details", { state: "plan", replace: true });
        }
      } else {
        showNotification(result.message, "error");
      }
    } catch (error) {
      showNotification(error.message, "error");
      localStorage.removeItem("meruwell_token");
    } finally {
      setLoading(false);
    }
  };

  const signInSchema = yup.object({
    mobile: yup
      .string()
      .trim()
      .required("Please enter mobile number")
      .matches(/^[0]?[6789]\d{9}$/, "Please enter valid number"),
    otp: yup
      .number()
      .positive()
      .integer()
      .when("mobile", (mobile, schema) =>
        mobile ? schema : schema.required("Please enter OTP")
      ),
  });

  const signInFormik = useFormik({
    initialValues: signInValues,
    validationSchema: signInSchema,
    onSubmit: async (values) => {},
  });

  const onSignUp = async (res) => {
    try {
      setLoading(true);
      const result = await loginController.signUp({
        code: res.code,
        method: "google",
      });

      if (result.success) {
        localStorage.setItem("meruwell_token", result.data.token);
        setRefresh(!refresh);
        showNotification(result.message, "success");
        handleClose();

        if (page === "plan") {
          history("/plan/details", { state: "plan", replace: true });
        }
      } else {
        showNotification(result.message, "error");
      }
    } catch (error) {
      showNotification(error.message, "error");
      localStorage.removeItem("meruwell_token");
    } finally {
      setLoading(false);
    }
  };

  const onFailure = (res) => {
    showNotification("Sign In Failed", "error");
  };

  const signIn = useGoogleLogin({
    onSuccess: onSuccess,
    onError: onFailure,
    flow: "auth-code",
  });

  const signUp = useGoogleLogin({
    onSuccess: onSignUp,
    onError: onFailure,
    flow: "auth-code",
  });

  const clearAll = (title = "Sign In") => {
    setModalTitle(title);
    setOtpInputView(false);
    setErrors({});
    setData({});
  };

  useEffect(() => {
    clearAll();
  }, [show]);

  useEffect(() => {}, [data]);

  const buttonDisableCheck =
    !data.mobile ||
    data?.mobile?.length < 10 ||
    errors.mobile ||
    !data.username ||
    errors.username ||
    !data.email ||
    errors.email;

  return (
    <Modal show={show} onHide={handleClose} backdrop="static">
      {loading && <Loader />}
      <Modal.Header closeButton className="px-3 py-2">
        <Modal.Title>{modalTitle}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="mb-4">
        {modalTitle === "Sign In" && (
          <div>
            <div className="d-flex justify-content-center">
              <div className="wrapperBox">
                <Form>
                  <Form.Group className="mb-3" controlId="Mobile Number">
                    <Form.Label className="mb-1">
                      <b>Mobile Number</b>
                    </Form.Label>
                    <Form.Control
                      type="tel"
                      disabled={otpInputView}
                      value={data?.mobile || ""}
                      onChange={(e) => {
                        let tel = e?.target?.value || "";
                        let pattern = /^[0]?[6789]\d{9}$/;
                        setData({ ...data, mobile: tel });

                        if (tel.length === 10 && !(tel && pattern.test(tel))) {
                          setErrors({
                            ...errors,
                            mobile: "Invalid Mobile Number",
                          });
                        } else {
                          setErrors({
                            ...errors,
                            mobile: "",
                          });
                        }
                      }}
                      onKeyDown={(e) => {
                        if (e.key.length === 1) {
                          let ascii = e.key.charCodeAt();
                          if (!(ascii >= 48 && ascii <= 57)) {
                            e.preventDefault();
                          }
                        }
                      }}
                    />
                    {errors.mobile ? (
                      <p className="mt-1 mb-0 text-danger">{errors.mobile}</p>
                    ) : (
                      <></>
                    )}
                  </Form.Group>
                  {otpInputView && (
                    <Form.Group className="mb-3" controlId="OTP">
                      <Form.Label className="mb-1">
                        <b>Enter OTP</b>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        value={data?.otp || ""}
                        onChange={(e) => {
                          setData({ ...data, otp: e?.target?.value || "" });
                        }}
                        onKeyDown={(e) => {
                          if (e.key.length === 1) {
                            let ascii = e.key.charCodeAt();
                            if (!(ascii >= 48 && ascii <= 57)) {
                              e.preventDefault();
                            }
                          }
                        }}
                      />
                    </Form.Group>
                  )}
                  <Button
                    variant="dark"
                    onClick={otpInputView ? () => {} : handleSignIn}
                    className="w-100"
                    disabled={
                      otpInputView
                        ? !data.otp || data?.otp?.length < 4
                        : !data.mobile ||
                          data?.mobile?.length < 10 ||
                          errors.mobile
                    }
                  >
                    {otpInputView ? "Verify" : "Continue"}
                  </Button>
                </Form>
              </div>
            </div>
            {!otpInputView && (
              <>
                <div className="d-flex justify-content-center my-4">
                  <div className="wrapperBox">
                    <p className="dividerText small">OR</p>
                  </div>
                </div>
                <div className="d-flex justify-content-center my-4">
                  <div className="wrapperBox">
                    <Button
                      variant="outline-dark"
                      onClick={signIn}
                      className="d-flex align-items-center justify-content-center w-100"
                    >
                      <img
                        src={Google}
                        width="20px"
                        height="20px"
                        className="me-2"
                        alt="Google"
                      />
                      <span className="align-middle">Login with Google</span>
                    </Button>
                  </div>
                </div>
              </>
            )}
            <div className="my-4">
              <p className="small text-center">
                Doesn't have an account?{" "}
                <span
                  className="text-primary text-decoration-underline cursor-pointer"
                  onClick={() => {
                    clearAll("Sign Up");
                  }}
                >
                  Sign up
                </span>
              </p>
            </div>
          </div>
        )}
        {modalTitle === "Sign Up" && (
          <div>
            <div className="d-flex justify-content-center">
              <div className="wrapperBox">
                <Form>
                  <Form.Group className="mb-3" controlId="Mobile Number">
                    <Form.Label className="mb-1">
                      <b>Mobile Number</b>
                    </Form.Label>
                    <Form.Control
                      type="tel"
                      disabled={otpInputView}
                      value={data?.mobile}
                      onChange={(e) => {
                        let tel = e?.target?.value || "";
                        let pattern = /^[0]?[6789]\d{9}$/;
                        setData({ ...data, mobile: tel });

                        if (tel.length === 10 && !(tel && pattern.test(tel))) {
                          setErrors({
                            ...errors,
                            mobile: "Invalid Mobile Number",
                          });
                        } else {
                          setErrors({
                            ...errors,
                            mobile: "",
                          });
                        }
                      }}
                      onKeyDown={(e) => {
                        if (e.key.length === 1) {
                          let ascii = e.key.charCodeAt();
                          if (!(ascii >= 48 && ascii <= 57)) {
                            e.preventDefault();
                          }
                        }
                      }}
                    />
                    {errors.mobile ? (
                      <p className="mt-1 mb-0 text-danger">{errors.mobile}</p>
                    ) : (
                      <></>
                    )}
                  </Form.Group>
                  {!otpInputView && (
                    <>
                      <Form.Group className="mb-3" controlId="User Name">
                        <Form.Label className="mb-1">
                          <b>User Name</b>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          value={data?.username}
                          onChange={(e) => {
                            setData({
                              ...data,
                              username: e?.target?.value?.trimStart() || "",
                            });
                          }}
                          onKeyDown={(e) => {
                            if (e.key.length === 1) {
                              let ascii = e.key.charCodeAt();
                              if (
                                !(
                                  (ascii >= 48 && ascii <= 57) ||
                                  (ascii >= 65 && ascii <= 90) ||
                                  (ascii >= 97 && ascii <= 122) ||
                                  ascii === 32
                                )
                              ) {
                                e.preventDefault();
                              }
                            }
                          }}
                        />
                      </Form.Group>
                      <Form.Group className="mb-3" controlId="Email">
                        <Form.Label className="mb-1">
                          <b>Email</b>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          value={data?.email}
                          onChange={(e) => {
                            let mail = e?.target?.value?.trimStart();
                            let pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                            setData({
                              ...data,
                              email: mail || "",
                            });

                            if (mail && !pattern.test(mail)) {
                              setErrors({
                                ...errors,
                                email: "Enter a Valid Email!",
                              });
                            } else {
                              setErrors({
                                ...errors,
                                email: "",
                              });
                            }
                          }}
                        />
                        {errors.email ? (
                          <p className="mt-1 mb-0 text-danger">
                            {errors.email}
                          </p>
                        ) : (
                          <></>
                        )}
                      </Form.Group>
                    </>
                  )}
                  {otpInputView && (
                    <Form.Group className="mb-3" controlId="OTP">
                      <Form.Label className="mb-1">
                        <b>Enter OTP</b>
                      </Form.Label>
                      <Form.Control type="text" />
                    </Form.Group>
                  )}
                  <Button
                    variant="dark"
                    onClick={otpInputView ? () => {} : handleSignIn}
                    className="w-100"
                    disabled={
                      otpInputView
                        ? !data.otp || data?.otp?.length < 4
                        : buttonDisableCheck
                    }
                  >
                    {otpInputView ? "Verify" : "Continue"}
                  </Button>
                </Form>
              </div>
            </div>
            {!otpInputView && (
              <>
                <div className="d-flex justify-content-center my-4">
                  <div className="wrapperBox">
                    <p className="dividerText small">OR</p>
                  </div>
                </div>
                <div className="d-flex justify-content-center my-4">
                  <div className="wrapperBox">
                    <Button
                      variant="outline-dark"
                      onClick={signUp}
                      className="d-flex align-items-center justify-content-center w-100"
                    >
                      <img
                        src={Google}
                        width="20px"
                        height="20px"
                        className="me-2"
                        alt="Google"
                      />
                      <span className="align-middle">Signup with Google</span>
                    </Button>
                  </div>
                </div>
              </>
            )}
            <div className="my-4">
              <p className="small text-center">
                Already have an account?{" "}
                <span
                  className="text-primary text-decoration-underline cursor-pointer"
                  onClick={() => {
                    clearAll("Sign In");
                  }}
                >
                  Sign In
                </span>
              </p>
            </div>
          </div>
        )}
      </Modal.Body>
      {/* <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button variant="primary" onClick={handleSignIn}>
          Save Changes
        </Button>
      </Modal.Footer> */}
    </Modal>
  );
}

export default LoginModal;
