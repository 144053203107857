import { useFormik } from "formik";
import React, { useState } from "react";
import * as yup from "yup";
import { Modal, Button, Form } from "react-bootstrap";
import Loader from "../../../Loader/Loader";
import { showNotification } from "../../../../utils/showNotification";
import { specializationController } from "../../../../api/admin/specialization";

function SpecializationModal({
  show,
  handleClose,
  title,
  data,
  refresh,
  setRefresh,
}) {
  const [loading, setLoading] = useState(false);

  const schema = yup.object({
    specName: yup
      .string()
      .trim()
      .matches(/^[a-zA-Z -]+$/, "Numbers and Special characters not allowed")
      .required("*This field is required"),
  });

  const formik = useFormik({
    initialValues: {
      specName: data?.specialization_name || "",
    },
    validationSchema: schema,
    enableReinitialize: true,
    onSubmit: async (values) => {
      try {
        setLoading(true);
        let res;

        if (title === "Add") {
          res = await specializationController.addSpecialization(values);
        } else if (title === "Update") {
          values.id = data.id;
          res = await specializationController.editSpecialization(values);
        } else if (title === "Delete") {
          res = await specializationController.deleteSpecialization(data.id);
        }

        if (res.success) {
          showNotification(res.message, "success");
          handleClose();
          formik.resetForm();
        } else {
          showNotification(res.message, "error");
        }
      } catch (err) {
        showNotification(err.message, "error");
      } finally {
        setLoading(false);
        setRefresh(!refresh);
      }
    },
  });

  return (
    <Modal show={show} onHide={handleClose} backdrop="static">
      {loading && <Loader />}
      <Modal.Header closeButton className="px-3 py-2">
        <Modal.Title>{`${title} Specialization`}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {title === "Delete" && (
          <p>
            Are you sure to delete the Specialization -{" "}
            <b>{formik.values.specName}</b> ?
          </p>
        )}
        {!(title === "Delete") && (
          <Form>
            <div>
              <label htmlFor="specName" className="form-label">
                Specialization Name*
              </label>
              <input
                type="text"
                className="form-control"
                name="specName"
                autoComplete="off"
                value={formik.values.specName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.errors.specName ? (
                <p className="mt-2 mb-0 text-danger">
                  {formik.errors.specName}
                </p>
              ) : (
                <></>
              )}
            </div>
          </Form>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={formik.handleSubmit}>
          {title}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default SpecializationModal;
