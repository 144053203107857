import React, { useEffect, useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { categoryController } from "../../../../api/admin/category";
import { showNotification } from "../../../../utils/showNotification";
import Loader from "../../../Loader/Loader";
import Select from "react-select";

function CategoryGroupModal({
  show,
  handleClose,
  title,
  data,
  setData,
  refresh,
  setRefresh,
  options,
}) {
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const handleSubmit = async () => {
    try {
      setLoading(true);
      const res = await categoryController.handleCategoryGroupActions(
        data,
        title
      );
      if (res.success) {
        showNotification(res.message, "success");
        handleClose();
      } else {
        showNotification(res.message, "error");
      }
    } catch (err) {
      showNotification(err.message, "error");
    } finally {
      setLoading(false);
      setRefresh(!refresh);
    }
  };

  useEffect(() => {
    setError("");
  }, [show]);

  return (
    <Modal show={show} onHide={handleClose} backdrop="static">
      {loading && <Loader />}
      <Modal.Header closeButton className="px-3 py-2">
        <Modal.Title>{`${title} Category`}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {title === "Delete" && (
          <p>
            Are you sure to delete the Category Group -{" "}
            <b>{data.category_name}</b> ?
          </p>
        )}
        {!(title === "Delete") && (
          <Form>
            <div className="mb-3">
              <label htmlFor="brand" className="form-label">
                Category Name*
              </label>
              <input
                type="text"
                className="form-control"
                id="category"
                autoComplete="off"
                value={data.category_name ? data.category_name : ""}
                onChange={(e) => {
                  if (e.target.value.length > 30) {
                    setError("Category length must be 30 or less");
                  } else {
                    setError("");
                  }
                  setData({ ...data, category_name: e.target.value || "" });
                }}
                onBlur={(e) => {
                  if (!e.target.value) {
                    setError("*Category Name is required");
                  }
                }}
                onKeyDown={(e) => {
                  if (e.key.length === 1) {
                    let ascii = e.key.charCodeAt();
                    if (
                      !(
                        (ascii >= 65 && ascii <= 90) ||
                        (ascii >= 97 && ascii <= 122) ||
                        ascii === 32
                      )
                    ) {
                      e.preventDefault();
                    }
                  }
                }}
              />
              {error ? <p className="mt-2 mb-0 text-danger">{error}</p> : <></>}
            </div>
            <div>
              <label htmlFor="brand" className="form-label">
                Categories List
              </label>
              <Select
                isMulti
                isClearable
                options={options}
                value={
                  data.category_id?.length
                    ? options.filter((opt) =>
                        data.category_id.includes(opt.value.toString())
                      )
                    : []
                }
                onChange={(e) => {
                  setData({
                    ...data,
                    category_id: e.map((d) => d.value.toString()),
                  });
                }}
              />
            </div>
          </Form>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="primary"
          disabled={!data.category_name || error}
          onClick={handleSubmit}
        >
          {title}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default CategoryGroupModal;
