import { httpRequest } from "../httpRequest";
import baseURL from "../../utils/urls";

const getAllCategories = async (filter) => {
  try {
    const response = await httpRequest({
      method: "post",
      baseURL: baseURL,
      url: "/admin/category",
      body: filter,
    });
    if (response.status >= 200 && response.status < 300) {
      return response.data;
    }
  } catch (err) {
    throw err;
  }
};

const handleCategoryActions = async (data, action) => {
  try {
    const response = await httpRequest({
      method: "post",
      baseURL: baseURL,
      url: "/admin/category/actions",
      body: { data, action },
    });
    if (response.status >= 200 && response.status < 300) {
      return response.data;
    }
  } catch (err) {
    throw err;
  }
};

const getAllCategoryGroups = async (filter) => {
  try {
    const response = await httpRequest({
      method: "post",
      baseURL: baseURL,
      url: "/admin/category/group",
      body: filter,
    });
    if (response.status >= 200 && response.status < 300) {
      return response.data;
    }
  } catch (err) {
    throw err;
  }
};

const handleCategoryGroupActions = async (data, action) => {
  try {
    const response = await httpRequest({
      method: "post",
      baseURL: baseURL,
      url: "/admin/category/group/actions",
      body: { data, action },
    });
    if (response.status >= 200 && response.status < 300) {
      return response.data;
    }
  } catch (err) {
    throw err;
  }
};

export const categoryController = {
  getAllCategories,
  handleCategoryActions,
  getAllCategoryGroups,
  handleCategoryGroupActions,
};
