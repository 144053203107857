import React from "react";
import { useNavigate } from "react-router-dom";
import jwt from "jsonwebtoken";
import "./Page404.css";

function Page404() {
  const history = useNavigate();

  const goBack = () => {
    const jwtToken = localStorage.getItem("meruwell_token");
    const decodeToken = jwt.decode(jwtToken);
    if (decodeToken?.isAdmin) {
      history("/admin");
    } else {
      history("/");
    }
  };

  return (
    <div className="not-found-container">
      <div className="d-md-flex text-white">
        <h2 className="title mb-4">404</h2>
        <div className="vr mx-5 d-none d-md-block"></div>
        <div className="mb-4 d-flex align-items-center">
          <div className="text-center">
            <h1>OOPS!</h1>
            <h5 className="mt-3">This Page doesn't exist!</h5>
            <button className="btn btn-dark mt-4" onClick={goBack}>
              <i className="bi bi-arrow-left me-2"></i>Go Back to Home
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Page404;
