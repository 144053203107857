import { httpRequest } from "../httpRequest";
import baseURL from "../../utils/urls";

const getAllSpecialization = async (body) => {
  try {
    const response = await httpRequest({
      method: "post",
      baseURL: baseURL,
      url: "/admin/specialization",
      body: body,
    });
    if (response.status >= 200 && response.status < 300) {
      return response.data;
    }
  } catch (err) {
    throw err;
  }
};

const addSpecialization = async (data) => {
  try {
    const response = await httpRequest({
      method: "post",
      baseURL: baseURL,
      url: "/admin/specialization/add",
      body: { data },
    });
    if (response.status >= 200 && response.status < 300) {
      return response.data;
    }
  } catch (err) {
    throw err;
  }
};

const editSpecialization = async (data) => {
  try {
    const response = await httpRequest({
      method: "post",
      baseURL: baseURL,
      url: "/admin/specialization/edit",
      body: { data },
    });
    if (response.status >= 200 && response.status < 300) {
      return response.data;
    }
  } catch (err) {
    throw err;
  }
};

const deleteSpecialization = async (id) => {
  try {
    const response = await httpRequest({
      method: "post",
      baseURL: baseURL,
      url: "/admin/specialization/delete",
      body: { id },
    });
    if (response.status >= 200 && response.status < 300) {
      return response.data;
    }
  } catch (err) {
    throw err;
  }
};

export const specializationController = {
  getAllSpecialization,
  addSpecialization,
  editSpecialization,
  deleteSpecialization,
};
