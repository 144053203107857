/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useState } from "react";
import { Navbar, Nav, Container, NavDropdown } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { loginController } from "../../../api/login";
import { showNotification } from "../../../utils/showNotification";
import "./CategoryNavbar.css";

function CategoryNavbar() {
  const history = useNavigate();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const [categoryGroups, setCategoryGroups] = useState([]);

  const navigate = (category) => {
    query.set("category", category);
    history({
      pathname: "/products",
      search: query.toString(),
    });
  };

  useEffect(async () => {
    try {
      const res = await loginController.getCategoryGroups();
      if (res.success) {
        setCategoryGroups(res.list);
      }
    } catch (error) {
      showNotification(error.message, "error");
    }
  }, []);
  return (
    <div className="category-nav">
      <Navbar variant="light" className="categoryNavbarContainer">
        <Container>
          <Nav className="categoryNavBar">
            <Nav.Link href="/products" className="fw-bolder">
              All Categories
            </Nav.Link>
            {categoryGroups?.map((grp) => {
              return (
                <NavDropdown
                  title={grp.category_name}
                  key={grp.id}
                  className="fw-bolder"
                >
                  {grp.categories?.map((cate, ind) => {
                    return (
                      <NavDropdown.Item
                        onClick={() => navigate(cate)}
                        className="py-2"
                        key={ind}
                      >
                        {cate}
                      </NavDropdown.Item>
                    );
                  })}
                </NavDropdown>
              );
            })}
          </Nav>
        </Container>
      </Navbar>
    </div>
  );
}

export default CategoryNavbar;
