import { httpRequest } from "./httpRequest";
import baseURL from "../utils/urls";

const saveEnquiry = async (data) => {
  try {
    const response = await httpRequest({
      method: "post",
      baseURL: baseURL,
      url: "/enquiry",
      body: data,
    });
    if (response.status >= 200 && response.status < 300) {
      return response.data;
    }
  } catch (err) {
    throw err;
  }
};

const subscribeToNewsLetter = async (data) => {
  try {
    const response = await httpRequest({
      method: "post",
      baseURL: baseURL,
      url: "/subscribe",
      body: data,
    });
    if (response.status >= 200 && response.status < 300) {
      return response.data;
    }
  } catch (err) {
    throw err;
  }
};

export const homeController = { saveEnquiry, subscribeToNewsLetter };
