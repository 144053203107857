/*eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useState } from "react";
import { productsController } from "../../../api/products";
import { showNotification } from "../../../utils/showNotification";
import { useLocation, useNavigate } from "react-router-dom";
import "./ProductPage.css";
import Loader from "../../Loader/Loader";
import baseURL from "../../../utils/urls";

function ProductPage() {
  const [products, setProducts] = useState();
  const [loading, setLoading] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const history = useNavigate();
  const location = useLocation();
  const query = new URLSearchParams(location.search);

  useEffect(async () => {
    try {
      setLoading(true);
      const result = await productsController.getAllProducts(
        query.get("search"),
        query.get("category")
      );
      if (result.success) {
        setProducts(result.productsList);
        setSearchValue(query.get("search"));
      }
    } catch (error) {
      showNotification(error.message, "error");
    } finally {
      setLoading(false);
    }
  }, [query.get("search"), query.get("category")]);

  return (
    <div className="container my-5">
      {loading && <Loader />}
      {!loading && (
        <div className="row">
          <h1 className="mb-5">
            {searchValue
              ? `Showing results for ${searchValue}`
              : query.get("category") || "All Categories"}
          </h1>
          {products?.length <= 0 && (
            <div className="px-5">
              <h3>OOPS!! No Results found</h3>
              <p>Try checking your spelling or use more general terms</p>
            </div>
          )}
          {products?.map((prod) => {
            return (
              <div className="col-12 col-md-4 col-lg-3 mb-5" key={prod.id}>
                <div
                  className="card p-1 productListBox"
                  onClick={() => {
                    history(
                      `/${
                        prod.type === "promotion"
                          ? "festive-offers"
                          : "products"
                      }/${prod.slug}`,
                      {
                        state: { id: prod.id },
                      }
                    );
                  }}
                >
                  <div className="position-relative">
                    <div className="d-flex justify-content-center">
                      <img
                        src={
                          prod?.product_image
                            ? `${baseURL}/uploads/${
                                prod?.product_image?.split(",")[0]
                              }`
                            : ""
                        }
                        className="card-img-top productImg"
                        alt={prod?.product_name}
                      />
                    </div>
                    {prod.total_quantity - prod.sold_quantity <= 0 && (
                      <div className="d-flex justify-content-center">
                        <span className="unavailable badge">
                          Currently Unavailable
                        </span>
                      </div>
                    )}
                  </div>
                  <div className="card-body">
                    <div className="card-text text-center h-100 d-flex flex-column justify-content-between">
                      <div>
                        <h5 className="text-secondary fw-bold">
                          {prod.product_brand}
                        </h5>
                        <p className="text-muted mb-1">{prod.product_name}</p>
                        <p className="text-muted font-xs">{prod.caption}</p>
                      </div>
                      <div>
                        <h6 className="mb-0">
                          ₹
                          {Math.round(
                            prod.unit_price +
                              (prod.unit_price * prod.gst) / 100 -
                              ((prod.unit_price +
                                (prod.unit_price * prod.gst) / 100) *
                                prod.discount) /
                                100
                          ).toFixed(2)}
                        </h6>
                        {prod.discount ? (
                          <p className="text-muted mt-1 mb-0 productPrice">
                            MRP:
                            <s>
                              ₹
                              {Math.round(
                                prod.unit_price +
                                  (prod.unit_price * prod.gst) / 100
                              ).toFixed(2)}
                            </s>{" "}
                            <b>({prod.discount}% off)</b>
                          </p>
                        ) : (
                          <></>
                        )}
                        <p className="mb-0 mt-1 font-xxs">
                          (Price inclusive of tax)
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
}

export default ProductPage;
