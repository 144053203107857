/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { cartContoller } from "../../../api/cart";
import { showNotification } from "../../../utils/showNotification";
import SyncLoader from "../../Loader/SyncLoader";
import style from "./Payment.module.css";

function Payment() {
  const location = useLocation();
  const history = useNavigate();
  const [loading, setLoading] = useState(true);
  const [status, setStatus] = useState("");
  const [fails, setFails] = useState(false);
  const { mode, success, message } = location.state;

  const getPaymentStatus = async () => {
    try {
      setLoading(true);
      const res = await cartContoller.getPaymentStatus(
        location.state.paymentId
      );
      if (!res.success) {
        showNotification(res.message, "error");
        setLoading(false);
      } else {
        let status = res.data.status;
        setStatus(status);
        if (status === "captured") {
          setLoading(false);
        }
        return status;
      }
    } catch (err) {
      showNotification(err.message, "error");
      setLoading(false);
    }
  };

  useEffect(() => {
    if (mode !== "cash") {
      var timer = setInterval(async () => {
        const paymentStatus = await getPaymentStatus();
        if (paymentStatus === "captured") {
          clearInterval(timer);
          clearTimeout(timeout);
        }
      }, 5000);

      const timeout = setTimeout(() => {
        if (status !== "captured") {
          setFails(true);
          clearInterval(timer);
          setLoading(false);
        }
      }, 30 * 1000);
    } else {
      setFails(!success);
      setLoading(false);
    }

    return () => clearInterval(timer);
  }, []);

  return (
    <div className="min-vh-100 d-flex justify-content-center align-items-center">
      {loading && (
        <div>
          {mode !== "cash" && "Please wait while we fetch your payment status"}
          <SyncLoader />
        </div>
      )}
      {!loading && fails && (
        <div className="text-center">
          <i className={`bi bi-x-circle-fill ${style.failedPayment}`}></i>
          {mode !== "cash" ? (
            <>
              <h3 className="my-4">Oh no! Something went wrong.</h3>
              <h6 className="my-4 text-danger">
                We aren't able to process your Payment. Please Try Again.
              </h6>
            </>
          ) : (
            <h3 className="my-4">{message}</h3>
          )}
          <button
            type="button"
            className="btn btn-primary"
            onClick={() => history("/cart", { replace: true })}
          >
            <i className="bi bi-arrow-left me-2"></i>Back to Cart
          </button>
        </div>
      )}
      {!loading && !fails && status === "captured" && (
        <div className="text-center">
          <i className={`bi bi-check-circle-fill ${style.successPayment}`}></i>
          <h3 className="my-4">Payment Successful!</h3>
          <button
            type="button"
            className="btn btn-primary"
            onClick={() => history("/cart", { replace: true })}
          >
            <i className="bi bi-arrow-left me-2"></i>Back to Cart
          </button>
        </div>
      )}
      {!loading && !fails && mode === "cash" && (
        <div className="text-center">
          <i className={`bi bi-check-circle-fill ${style.successPayment}`}></i>
          <h3 className="my-4">{message}</h3>
          <button
            type="button"
            className="btn btn-primary"
            onClick={() => history("/cart", { replace: true })}
          >
            <i className="bi bi-arrow-left me-2"></i>Back to Cart
          </button>
        </div>
      )}
    </div>
  );
}

export default Payment;
