import { httpRequest } from "../httpRequest";
import baseURL from "../../utils/urls";

const getAllDoctors = async (filter) => {
  try {
    const response = await httpRequest({
      method: "post",
      baseURL: baseURL,
      url: "/admin/doctors",
      body: filter,
    });
    if (response.status >= 200 && response.status < 300) {
      return response.data;
    }
  } catch (err) {
    throw err;
  }
};

const getDoctorById = async (id) => {
  try {
    const response = await httpRequest({
      method: "get",
      baseURL: baseURL,
      url: `/admin/doctors/${id}`,
    });
    if (response.status >= 200 && response.status < 300) {
      return response.data;
    }
  } catch (err) {
    throw err;
  }
};

const addDoctor = async (data) => {
  try {
    const response = await httpRequest({
      method: "post",
      baseURL: baseURL,
      url: "/admin/doctors/add",
      body: data,
      contentType: "multipart/form-data",
    });
    if (response.status >= 200 && response.status < 300) {
      return response.data;
    }
  } catch (err) {
    throw err;
  }
};

const editDoctor = async (data, fileChanged = 0) => {
  try {
    const response = await httpRequest({
      method: "post",
      baseURL: baseURL,
      url: "/admin/doctors/edit/" + fileChanged,
      body: data,
      contentType: fileChanged ? "multipart/form-data" : "application/json",
    });
    if (response.status >= 200 && response.status < 300) {
      return response.data;
    }
  } catch (err) {
    throw err;
  }
};

const deleteDoctor = async (id) => {
  try {
    const response = await httpRequest({
      method: "post",
      baseURL: baseURL,
      url: "/admin/doctors/delete",
      body: { id },
    });
    if (response.status >= 200 && response.status < 300) {
      return response.data;
    }
  } catch (err) {
    throw err;
  }
};

export const adminDoctorsController = {
  getAllDoctors,
  getDoctorById,
  addDoctor,
  editDoctor,
  deleteDoctor,
};
