import { httpRequest } from "./httpRequest";
import baseURL from "../utils/urls";

const getAllBlogs = async (limit) => {
  try {
    const response = await httpRequest({
      method: "post",
      baseURL: baseURL,
      url: `/blogs`,
      body: { limit },
    });
    if (response.status >= 200 && response.status < 300) {
      return response.data;
    }
  } catch (err) {
    throw err;
  }
};

const getBlogBySlug = async (slug) => {
  try {
    const response = await httpRequest({
      method: "post",
      baseURL: baseURL,
      url: `/blogs/getBlog`,
      body: { slug },
    });
    if (response.status >= 200 && response.status < 300) {
      return response.data;
    }
  } catch (err) {
    throw err;
  }
};

export const blogsController = { getAllBlogs, getBlogBySlug };
