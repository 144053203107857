import React, { useState } from "react";
import Loader from "../../../Loader/Loader";
import { Modal, Button } from "react-bootstrap";
import { showNotification } from "../../../../utils/showNotification";
import { adminDoctorsController } from "../../../../api/admin/doctors";

function DoctorsModal({ show, handleClose, data, refresh, setRefresh }) {
  const [loading, setLoading] = useState(false);

  const handleSubmit = async () => {
    try {
      setLoading(true);
      const res = await adminDoctorsController.deleteDoctor(data.id);

      if (res.success) {
        showNotification(res.message, "success");
        handleClose();
      } else {
        showNotification(res.message, "error");
      }
    } catch (err) {
      showNotification(err.message, "error");
    } finally {
      setLoading(false);
      setRefresh(!refresh);
    }
  };

  return (
    <Modal show={show} onHide={handleClose} backdrop="static">
      {loading && <Loader />}
      <Modal.Header closeButton className="px-3 py-2">
        <Modal.Title>{`Delete Doctor`}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          Are you sure to delete the data of{" "}
          <b>{data.salutation + "." + data.doctor_name}</b> ?
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={handleSubmit}>
          Delete
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default DoctorsModal;
