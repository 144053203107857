import React from "react";
import "./Loader.css";

function SyncLoader() {
  return (
    <div>
      <div className="spinner">
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  );
}

export default SyncLoader;
