export const productInfo = [
  {
    name: "Ashwagandha Green Tea",
    description: "",
    preview: "diwali/ashwagandha-tea-preview.jpg",
    image: "diwali/ashwagandha-tea.jpg",
    benefits: `<ul>
      <li>Improved Brain Function: The combination of caffeine and L-theanine in green tea can improve brain function, enhancing mood, vigilance, and reaction time.</li>
      <li>Metabolism Boost: Green tea is known to boost the metabolic rate in the short term, potentially aiding in weight loss efforts.</li>
      <li>Immune System Support: The blend of green tea and Ashwagandha can support immune function, helping the body ward off illnesses.</li>
      </ul>`,
  },
  {
    name: "Nendran Banana Chips",
    description: "",
    preview: "diwali/nendran-banana-chips-preview.jpg",
    image: "diwali/nendran-banana-chips.jpg",
    benefits: `<ul>
    <li>Rich in Nutrients: Like other bananas, Nendran bananas are a good source of several vitamins and minerals, especially potassium, vitamin B6, and vitamin C.</li>
    <li>Digestive Health: Nendran bananas provide dietary fiber, which can promote digestive health by preventing constipation and supporting regular bowel movements.</li>
    <li>Heart Health: Potassium found in bananas is known to be heart-healthy. It can help regulate blood pressure levels and is linked to a reduced risk of stroke.</li>
    </ul>`,
  },
  {
    name: "Palm Jaggery with Sukku Candy",
    description: "",
    preview: "diwali/palm-jaggery-sukku-candy-preview.jpg",
    image: "diwali/palm-jaggery-sukku-candy.jpg",
    benefits: `<ul>
    <li>Natural Sweetener: Palm jaggery is a more natural sweetener compared to refined sugars. It undergoes minimal processing, retaining more natural nutrients.</li>
    <li>Rich in Nutrients: Palm jaggery is a source of vitamins and minerals like magnesium, potassium, iron, zinc, and vitamins B1, B2, B3, and B6. These nutrients contribute to various physiological functions, such as nerve function, blood formation, and immune response.</li>
    <li>Antioxidant Properties: Palm jaggery contains antioxidants, which can help combat oxidative stress in the body, reducing the risk of chronic diseases.</li>
    </ul>`,
  },
  {
    name: "Amla Candy",
    description: "",
    preview: "diwali/amla-candy-preview.jpg",
    image: "diwali/amla-candy.jpg",
    benefits: `<ul>
    <li>Enhances Digestion: Amla candy contains dietary fiber, which aids in digestion and prevents constipation. It acts as a natural digestive cleanser, promoting a healthy digestive system.</li>
    <li>Improves Skin Health: The high vitamin C content in amla candy promotes collagen production, contributing to healthier, more youthful-looking skin. Amla's antioxidants also help in reducing skin aging signs and improving overall skin texture.</li>
    <li>Supports Hair Health: Amla is renowned for its benefits to hair. Regular consumption of amla candy strengthens hair follicles, reduces hair loss, and promotes shiny, lustrous hair. It also helps prevent premature graying due to its rich content of antioxidants and essential nutrients.</li>
    </ul>`,
  },
  {
    name: "Avarampoo Tea",
    description: "",
    preview: "diwali/avarampoo-tea-preview.jpg",
    image: "diwali/avarampoo-tea.jpg",
    benefits: `<ul>
    <li>Diuretic Properties: Avarampoo tea is a natural diuretic, which means it promotes urine production. This can help in flushing out toxins from the body and supporting kidney health.</li>
    <li>Digestive Health: Avarampoo tea is often used to alleviate digestive issues. It can aid in digestion and may provide relief from constipation and bloating.</li>
    <li>Weight Management: Some people use avarampoo tea as a part of their weight management routine due to its diuretic properties, which can help in reducing water retention.</li>
    </ul>`,
  },
  {
    name: "Rose Honey Gulkand",
    description: "",
    preview: "diwali/rose-honey-gulkand-preview.jpg",
    image: "diwali/rose-honey-gulkand.jpg",
    benefits: `<ul>
    <li>Cooling Effect: Gulkand, especially when infused with rose and honey, is known for its cooling properties. It helps in reducing body heat, making it particularly beneficial during hot weather.</li>
    <li>Stress Reduction: Rose honey gulkand has a calming effect on the nervous system. It can help reduce stress and anxiety, promoting relaxation and mental well-being. Its soothing properties make it a popular choice for individuals looking to manage stress naturally.</li>
    <li>Improves Respiratory Health: Rose honey gulkand is often used to alleviate respiratory problems such as cough and asthma. The natural soothing effect of rose combined with the antibacterial properties of honey can provide relief from throat irritation and respiratory discomfort.</li>
    </ul>`,
  },
];
