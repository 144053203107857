/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */

import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import "../Cart.css";
import { displayRazorpay } from "../../../utils/razorpayHelper";
import { showNotification } from "../../../utils/showNotification";
import Loader from "../../Loader/Loader";
import { cartContoller } from "../../../api/cart";
import { useNavigate } from "react-router-dom";

function PaymentModeModal({ show, handleClose, refresh, setRefresh }) {
  const [loading, setLoading] = useState(false);
  const [mode, setMode] = useState("cash");
  const history = useNavigate();

  const orderItems = async () => {
    try {
      setLoading(true);
      if (mode === "online") {
        const res = await cartContoller.orderItems();
        if (!res.success) {
          showNotification(res.message, "error");
        } else {
          // razor pay options
          var razorpay_options = {
            description: "Meruwell Order payment",
            image: "",
            handler: async function (response) {
              history(`/payment`, {
                state: { paymentId: response.razorpay_payment_id },
                replace: true,
              });
            },
            theme: {
              color: "#3399cc",
            },
          };

          displayRazorpay({ ...res.data, ...razorpay_options });
        }

        //Instamojo payment gateway
        // const res = await cartContoller.orderItemsWithInstamojo();
        // if (!res.success) {
        //   showNotification(res.message, "error");
        // } else {
        //   // console.log(res)
        //   window.location.href = res.redirect;
        // }
      } else {
        const res = await cartContoller.orderItemsWithCash();
        history(`/payment`, {
          state: { mode: "cash", message: res.message, success: res.success },
          replace: true,
        });
      }
    } catch (err) {
      showNotification(err, "error");
    } finally {
      setLoading(false);
      setRefresh(!refresh);
    }
  };
  return (
    <Modal
      show={show}
      onHide={() => {
        handleClose();
      }}
      backdrop="static"
      scrollable={true}
    >
      {loading && <Loader />}
      <Modal.Header closeButton className="px-3 py-2">
        <h5 className="mb-0 py-2">Select Payment mode</h5>
      </Modal.Header>
      <Modal.Body>
        <div>
          <div className="form-check mb-2">
            <input
              className="form-check-input"
              type="radio"
              name="paymentMode"
              id="cash"
              checked={mode === "cash"}
              onChange={(e) => setMode(e.target.id)}
            />
            <label className="form-check-label" for="cash">
              Cash on Delivery
            </label>
          </div>
          <div className="form-check">
            <input
              className="form-check-input"
              type="radio"
              name="paymentMode"
              id="online"
              checked={mode === "online"}
              // disabled
              onChange={(e) => setMode(e.target.id)}
            />
            <label className="form-check-label" for="online">
              Pay Online
            </label>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button type="button" className="btn btn-primary" onClick={orderItems}>
          Proceed
        </button>
      </Modal.Footer>
    </Modal>
  );
}

export default PaymentModeModal;
