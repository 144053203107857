import { httpRequest } from "../httpRequest";
import baseURL from "../../utils/urls";

const getProductById = async (id) => {
  try {
    const response = await httpRequest({
      method: "get",
      baseURL: baseURL,
      url: `/admin/getProduct/${id}`,
    });
    if (response.status >= 200 && response.status < 300) {
      return response.data;
    }
  } catch (err) {
    throw err;
  }
};

const addProduct = async (data) => {
  try {
    const response = await httpRequest({
      method: "post",
      baseURL: baseURL,
      url: "/admin/addProduct",
      body: data,
      contentType: "multipart/form-data",
    });
    if (response.status >= 200 && response.status < 300) {
      return response.data;
    }
  } catch (err) {
    throw err;
  }
};

const editProduct = async (data, fileChanged = 0) => {
  try {
    const response = await httpRequest({
      method: "post",
      baseURL: baseURL,
      url: "/admin/editProduct/" + fileChanged,
      body: data,
      contentType: fileChanged ? "multipart/form-data" : "application/json",
    });
    if (response.status >= 200 && response.status < 300) {
      return response.data;
    }
  } catch (err) {
    throw err;
  }
};

export const adminProductController = {
  addProduct,
  editProduct,
  getProductById,
};
