function loadScript(src) {
  return new Promise((resolve) => {
    const script = document.createElement("script");
    script.src = src;
    script.onload = () => {
      resolve(true);
    };
    script.onerror = () => {
      resolve(false);
    };
    document.body.appendChild(script);
  });
}

// to load the razorpay client checkout
async function displayRazorpay(options) {
  const res = await loadScript("https://checkout.razorpay.com/v1/checkout.js");

  if (!res) {
    alert("Razorpay SDK failed to load. Are you online?");
    return;
  }
  if (window) {
    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
  } else alert("Something went wrong !");
}

export { displayRazorpay, loadScript };
