/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useState } from "react";
import { MDBDataTableV5 } from "mdbreact";
import { showNotification } from "../../../../utils/showNotification";
import Loader from "../../../Loader/Loader";
import { useNavigate } from "react-router-dom";
import { ordersController } from "../../../../api/admin/orders";

function ListOrders() {
  const history = useNavigate();

  const [loading, setLoading] = useState(false);
  const [orders, setOrders] = useState({
    columns: [
      {
        label: "Order Id",
        field: "order_id",
      },
      {
        label: "Ordered On",
        field: "created_at",
      },
      {
        label: "Status",
        field: "order_status",
      },
      {
        label: "View PDF",
        field: "action",
      },
    ],
    rows: [],
  });

  useEffect(async () => {
    try {
      setLoading(true);
      const result = await ordersController.getAllOrders();
      if (result.success) {
        setOrders({
          ...orders,
          rows: result.ordersList.map((order) => {
            return {
              ...order,
              created_at: new Date(order.created_at).toLocaleDateString(
                "en-GB"
              ),
              action: (
                <>
                  <span
                    className="me-3"
                    onClick={() => history(`/admin/orders/${order.id}`)}
                  >
                    <i className="icons bi bi-file-earmark-pdf-fill"></i>
                  </span>
                </>
              ),
            };
          }),
        });
      }
    } catch (error) {
      showNotification(error.message, "error");
    } finally {
      setLoading(false);
    }
  }, []);

  return (
    <div className="container mt-5">
      {loading && <Loader />}
      <h2>ORDERS LIST</h2>
      <div className="mt-4">
        {!loading && (
          <MDBDataTableV5
            data={orders}
            searching={false}
            entriesOptions={[8, 16, 24, 40]}
            entries={8}
            noBottomColumns={true}
            noRecordsFoundLabel={"No Orders Found"}
            responsive
            sortable={false}
          />
        )}
      </div>
    </div>
  );
}

export default ListOrders;
