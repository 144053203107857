/* eslint-disable react-hooks/exhaustive-deps */

import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { doctorsController } from "../../api/doctors";
import { showNotification } from "../../utils/showNotification";
import baseURL from "../../utils/urls";
import Footer from "../Home/Footer";
import Header from "../Home/Header";
import Loader from "../Loader/Loader";
import parse from "react-html-parser";
import Select from "react-select";
import experienceIcon from "../../assets/icons/experience-icon.svg";
import { Debounce } from "../../utils/debounce";
import style from "./Doctors.module.css";
import { specializationController } from "../../api/admin/specialization";

function Doctors({ setLoginShow }) {
  const [doctorsList, setDoctorsList] = useState([]);
  const [specializationOptions, setSpecializationOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filters, setFilters] = useState({
    doctorName: "",
    city: "",
    specialization: "",
  });

  let debouncedFilter = Debounce(filters, 800);

  const getAllDoctors = async () => {
    try {
      setLoading(true);
      const res = await doctorsController.getAllDoctors(filters);
      if (res.success) {
        setDoctorsList(res.doctorsList);
      } else {
        showNotification(res.message);
      }
    } catch (error) {
      showNotification(error.message, "error");
    } finally {
      setLoading(false);
    }
  };

  const getAllSpecializations = async () => {
    try {
      setLoading(true);
      const res = await specializationController.getAllSpecialization({
        dropdown: true,
      });
      if (res.success) {
        setSpecializationOptions(res.data);
      } else {
        showNotification(res.message);
      }
    } catch (error) {
      showNotification(error.message, "error");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getAllDoctors();
    getAllSpecializations();
  }, [debouncedFilter]);

  return (
    <div>
      <Header setLoginShow={setLoginShow} />
      {loading && <Loader />}
      <div className="container my-5">
        <h1 className="text-center fw-bold">Doctors & Clinics</h1>
        <div className={`d-md-flex gap-3 ${style.searchBox}`}>
          <div className="mb-3">
            <input
              type="text"
              className={"form-control " + style.searchContainer}
              id="doctors"
              placeholder="Search Doctors"
              value={filters.doctorName}
              onChange={(e) =>
                setFilters({ ...filters, doctorName: e.target.value || "" })
              }
              autoComplete="off"
            />
          </div>
          <div className="mb-3">
            <input
              type="text"
              className={"form-control " + style.searchContainer}
              id="doctors"
              placeholder="Search Doctors by City"
              value={filters.city}
              onChange={(e) =>
                setFilters({ ...filters, city: e.target.value || "" })
              }
              autoComplete="off"
            />
          </div>
          <div className="mb-3">
            <Select
              options={specializationOptions}
              styles={{
                // Fixes the overlapping problem of the component
                menu: (provided) => ({ ...provided, zIndex: 999 }),
              }}
              value={specializationOptions.filter(
                (opt) => opt.value === filters.specialization
              )}
              id="specialization"
              onChange={(e) => {
                setFilters({
                  ...filters,
                  specialization: e ? e.value : "",
                });
              }}
              placeholder="Specialization"
              className={style.searchContainer}
              isClearable
            />
          </div>
        </div>
        <div className={style.doctorsContainer}>
          <div className="row mb-4">
            {!loading && doctorsList.length === 0 && (
              <h4>No Doctors data found</h4>
            )}
            {doctorsList.map((doc) => {
              return (
                <div className="mb-5" key={doc.id}>
                  <div className="card px-2 py-4">
                    <div className="row">
                      <div className="col-md-4 col-lg-3 d-flex align-items-center justify-content-center px-0">
                        <div className={style.imgBox}>
                          <img
                            src={
                              doc.profile
                                ? `${baseURL}/uploads/doctors/${doc.profile}`
                                : ""
                            }
                            alt={doc.doctor_name}
                            className="card-img-top img-fluid"
                          />
                        </div>
                      </div>
                      <div className="col-md-8 col-lg-9">
                        <div className="card-body">
                          <h5 className="fw-semibold">
                            {doc.doctor_name
                              ? doc.salutation + ". " + doc.doctor_name
                              : doc.clinic_name}
                          </h5>
                          <hr className="w-75 mx-0 bg-dark" />
                          <div className="row font-small">
                            <div className="col-lg-6">
                              <p className="d-flex">
                                <i className="bi bi-geo-alt-fill me-3" />
                                <span>
                                  {doc.doctor_name && (
                                    <>
                                      <b>{doc.clinic_name}</b>
                                      <br />
                                    </>
                                  )}
                                  <span>
                                    {[
                                      doc.clinic_address,
                                      doc.city,
                                      doc.state,
                                    ].join(", ")}{" "}
                                    - {doc.pincode}
                                  </span>
                                </span>
                              </p>
                              {doc.phone_number && (
                                <p className="d-flex">
                                  <i className="bi bi-telephone-fill me-3" />
                                  <span>{doc.phone_number}</span>
                                </p>
                              )}
                            </div>
                            <div className="col-lg-6">
                              {doc.experience && (
                                <p className="d-flex">
                                  <img
                                    src={experienceIcon}
                                    alt="icon"
                                    width={20}
                                    height={20}
                                  />
                                  <span className="ms-2">
                                    {doc.experience} Years of Experience
                                  </span>
                                </p>
                              )}
                              <p className="d-flex">
                                <i className="bi bi-award-fill me-3"></i>
                                <span>{doc.specialization_names}</span>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row mt-4 px-4">
                      <h6 className="fw-semibold">About:</h6>
                      <p className="text-muted mt-2">{parse(doc.details)}</p>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Doctors;
