import React from "react";
import "./HomePage.css";
import { useFormik } from "formik";
import * as yup from "yup";
import { homeController } from "../../api/home";
import { showNotification } from "../../utils/showNotification";

function Footer() {
  const schema = yup.object().shape({
    email: yup
      .string()
      .required("Please fill the Email")
      .email("Enter a valid email"),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: schema,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: async (values) => {
      const res = await homeController.subscribeToNewsLetter(values);
      if (res.success) {
        formik.resetForm();
        showNotification(res.message, "success");
      } else {
        showNotification(res.message);
      }
    },
  });

  return (
    <div>
      <div className="contact-container container-fluid pt-5 px-5 pb-0">
        <div className="d-flex justify-content-center">
          <div className="row mt-4 footerBox">
            <div className="col-md-6 col-lg-3 mb-5">
              <div>
                <h6 className="fw-bold">Information</h6>
                <hr className="w-25" />
                <ul>
                  <li>
                    <a href="/blogs">Blogs</a>
                  </li>
                  <li>
                    <a href="/doctors">Doctors</a>
                  </li>
                  <li>
                    <a href="/terms-and-conditions">Terms & Conditions</a>
                  </li>
                  <li>
                    <a href="/privacy-policy">Privacy Policy</a>
                  </li>
                  <li>
                    <a href="/return-and-refund-policy">
                      Return and Refund Policy
                    </a>
                  </li>
                  <li>
                    <a href="/shipping-policy">Shipping Policy</a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-6 col-lg-2 mb-5">
              <div>
                <h6 className="fw-bold">Support</h6>
                <hr className="w-25" />
                <ul>
                  <li>
                    <a href="/about-us">About Us</a>
                  </li>
                  <li>
                    <a href="/contact-us">Contact Us</a>
                  </li>
                  <li>
                    <a href="/products" target="_blank">
                      Shop
                    </a>
                  </li>
                  <li>
                    <a href="/blogs">Order Tracking</a>
                  </li>
                  <li>
                    <a href="/blogs">FAQ</a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-6 col-lg-4 mb-5">
              <div>
                <h6 className="fw-bold">Contact Us</h6>
                <hr className="w-25" />
                <div className="mb-2 d-flex">
                  <i className="bi bi-geo-alt-fill me-2" />
                  <div>
                    <b>Sanatan Ventures</b>
                    <br />
                    <li className="mb-2">
                      277-B1, Thadagam Main Road, Venkitapuram, Coimbatore,
                      TamilNadu - 641025
                    </li>
                    <li>
                      AECS Layout, Block A, Singasandra, Bangalore, Karnataka -
                      560068
                    </li>
                  </div>
                </div>
                <a
                  href="mailto:hello@meruwell.com"
                  className="text-dark text-decoration-none"
                >
                  <p className="mb-2 d-flex">
                    <i className="bi bi-envelope-fill me-2" />
                    <span>hello@meruwell.com</span>
                  </p>
                </a>
                <a
                  href="tel:+919442406780"
                  className="text-dark text-decoration-none"
                >
                  <p className="d-flex">
                    <i className="bi bi-telephone-fill me-2" />
                    <span>+91 94424 06780</span>
                  </p>
                </a>
              </div>
            </div>
            <div className="col-md-6 col-lg-3 mb-5">
              <div>
                <h6 className="fw-bold">Our Newsletter</h6>
                <hr className="w-25" />
                <div>
                  <p>
                    Sign up here to get the latest news, updates and special
                    offers delivered to your inbox.
                  </p>
                  <div className="mb-3">
                    <form onSubmit={formik.handleSubmit}>
                      <input
                        type="text"
                        className="form-control form-control-sm mb-1"
                        placeholder="Enter Email Address"
                        name="email"
                        value={formik.values.email}
                        onChange={formik.handleChange}
                      />
                      {formik.errors.email && (
                        <p className="text-danger mb-0">
                          {formik.errors.email}
                        </p>
                      )}
                      <button
                        className="btn btn-sm fw-bold mt-2 subscriptionBtn"
                        type="submit"
                        id="button-email"
                      >
                        Submit
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="text-center pb-4">
          <h6>Follow us on</h6>
          <div className="social-media-cta">
            <a
              href="https://www.facebook.com/meruwellhealth"
              target="_blank"
              rel="noreferrer"
            >
              <i className="bi bi-facebook me-3" />
            </a>
            <a href="/">
              <i className="bi bi-twitter-x me-3" />
            </a>
            <a
              href="https://www.instagram.com/meruwellhealth"
              target="_blank"
              rel="noreferrer"
            >
              <i className="bi bi-instagram me-3" />
            </a>
            <a href="/">
              <i className="bi bi-linkedin me-3" />
            </a>
            <a
              href="https://www.youtube.com/@meruwell1893"
              target="_blank"
              rel="noreferrer"
            >
              <i className="bi bi-youtube me-3 font-small" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
