import { httpRequest } from "../httpRequest";
import baseURL from "../../utils/urls";

const getAllBlogs = async () => {
  try {
    const response = await httpRequest({
      method: "post",
      baseURL: baseURL,
      url: `/admin/blogs`,
    });
    if (response.status >= 200 && response.status < 300) {
      return response.data;
    }
  } catch (err) {
    throw err;
  }
};

const getBlogById = async (id) => {
  try {
    const response = await httpRequest({
      method: "get",
      baseURL: baseURL,
      url: `/admin/blogs/getBlog/${id}`,
    });
    if (response.status >= 200 && response.status < 300) {
      return response.data;
    }
  } catch (err) {
    throw err;
  }
};

const addBlog = async (data) => {
  try {
    const response = await httpRequest({
      method: "post",
      baseURL: baseURL,
      url: "/admin/blogs/addBlog",
      body: data,
      contentType: "multipart/form-data",
    });
    if (response.status >= 200 && response.status < 300) {
      return response.data;
    }
  } catch (err) {
    throw err;
  }
};

const editBlog = async (data, fileChanged = 0) => {
  try {
    const response = await httpRequest({
      method: "post",
      baseURL: baseURL,
      url: "/admin/blogs/editBlog/" + fileChanged,
      body: data,
      contentType: fileChanged ? "multipart/form-data" : "application/json",
    });
    if (response.status >= 200 && response.status < 300) {
      return response.data;
    }
  } catch (err) {
    throw err;
  }
};

export const adminBlogsController = {
  getAllBlogs,
  getBlogById,
  addBlog,
  editBlog,
};
