import React from "react";
import { Button, Form } from "react-bootstrap";
import Footer from "../Home/Footer";
import Header from "../Home/Header";
import style from "./ContactUs.module.css";
import { useFormik } from "formik";
import * as yup from "yup";
import { homeController } from "../../api/home";
import { showNotification } from "../../utils/showNotification";

function ContactUs({ setLoginShow }) {
  const schema = yup.object().shape({
    fullName: yup.string().required("Please fill the Full Name"),
    email: yup.string().required("Please fill the Email").email(),
    mobile: yup
      .string()
      .required("Please fill the Mobile Number")
      .matches(/^[6-9]\d{9}$/, "Please enter valid number"),
    message: yup.string().required("Please fill the Message"),
  });

  const formik = useFormik({
    initialValues: {
      fullName: "",
      email: "",
      mobile: "",
      message: "",
    },
    validationSchema: schema,
    onSubmit: async (values) => {
      const res = await homeController.saveEnquiry(values);
      if (res.success) {
        formik.resetForm();
        showNotification(res.message, "success");
      }
    },
  });
  return (
    <div>
      <Header setLoginShow={setLoginShow} />
      <div className="container my-5">
        <h1 className="text-center fw-bold mb-5">Get in Touch with MeruWell</h1>
        <p>
          We're here to assist you on your journey to holistic well-being.
          Whether you have questions about our products, need guidance on
          selecting the right wellness solutions, or simply want to share your
          feedback, we'd love to hear from you. Feel free to reach out through
          any of the following channels:
        </p>
        <br />
        <h6>
          <b>Customer Support:</b>
        </h6>
        <p>
          Our dedicated customer support team is available to answer your
          queries and address any concerns you may have. You can reach us via
          email at <a href="mailto:hello@meruwell.com">hello@meruwell.com</a> or
          by filling out the contact form below.
        </p>
        <br />
        <h6>
          <b>Live Chat:</b>
        </h6>
        <p>
          Chat live with one of our wellness experts for real-time assistance.
          Look for the chat icon in the bottom right corner of your screen to
          start a conversation.
        </p>
        <br />
        <h6>
          <b>Phone:</b>
        </h6>
        <p>
          Prefer speaking directly with a member of our team? Give us a call at{" "}
          <a href="tel:+919442406780">+91 94424 06780</a> during our business
          hours, Monday through Friday, 9:00 AM to 5:00 PM PST.
        </p>
        <br />
        <h6>
          <b>Visit Us:</b>
        </h6>
        <p>
          Visit us if you're in the neighborhood, we welcome you to visit our
          office located at: <br />
          <br />
          MeruWell Health
          <br />
          Promoted by: <b>Sanatan Ventures</b>,<br />
          277-B1, Thadagam Main Road, Venkitapuram,
          <br />
          Coimbatore, TamilNadu - 641025
        </p>
        <br />
        <h6>
          <b>Connect on Social Media:</b>
        </h6>
        <p>
          Stay updated on the latest news, wellness tips, and exclusive offers
          by following us on social media. Connect with us on
          <ul className="mt-3 list-unstyled">
            <li>
              <a
                href="https://www.facebook.com/meruwellhealth"
                target="_blank"
                rel="noreferrer"
                className="text-dark"
              >
                <i className="bi bi-facebook me-3" />
                Facebook
              </a>
            </li>
            <li>
              <a
                href="https://www.instagram.com/meruwellhealth"
                target="_blank"
                rel="noreferrer"
                className="text-dark"
              >
                <i className="bi bi-instagram me-3" />
                Instagram
              </a>
            </li>
            <li>
              <a
                href="https://www.youtube.com/@meruwell1893"
                target="_blank"
                rel="noreferrer"
                className="text-dark"
              >
                <i className="bi bi-youtube me-3" />
                Youtube
              </a>
            </li>
          </ul>
        </p>
        <br />
        <h6>
          <b>We Value Your Feedback:</b>
        </h6>
        <p>
          At MeruWell, we're committed to providing exceptional service and
          empowering you to thrive in your holistic journey. Your feedback is
          invaluable to us as we continuously strive to enhance our offerings
          and better serve your needs. Please don't hesitate to reach out with
          any questions, comments, or suggestions. We're here to support you
          every step of the way.
        </p>
        <br />
        <h6>
          <b>
            Thank you for choosing MeruWell as your partner in holistic
            wellness.
          </b>
        </h6>
        <br />
        <p className="text-center text-muted mt-4">
          Any questions or remarks? Just write us a message here!
        </p>
        <div
          className={`d-flex justify-content-center ${style.contactUsFormBox}`}
        >
          <div className={style.contactUsFormContainer}>
            <div className="row">
              <div
                className={
                  "col-lg-6 d-flex align-items-center justify-content-md-center mb-5 " +
                  style.contactInfo
                }
              >
                <div className={style.infoContainer}>
                  <div className={style.infoCover}>
                    <div className="mb-2 d-flex">
                      <i className="bi bi-geo-alt-fill me-3" />
                      <div>
                        <b>MeruWell Health</b>
                        <br />
                        Promoted by: <b>Sanatan Ventures</b>
                        <br />
                        <li className="mb-2">
                          277-B1, Thadagam Main Road, Venkitapuram, Coimbatore,
                          TamilNadu - 641025
                          <p className="d-flex">
                            <a
                              href="tel:919442406780"
                              className="text-white text-decoration-none"
                            >
                              <i className="bi bi-telephone-fill me-3" />
                              <span>+91 94424 06780</span>
                            </a>
                          </p>
                        </li>
                        <li>
                          AECS Layout, Block A, Singasandra, Bangalore,
                          Karnataka - 560068
                          <p className="d-flex">
                            <a
                              href="tel:+919845845616"
                              className="text-white text-decoration-none"
                            >
                              <i className="bi bi-telephone-fill me-3" />
                              <span>+91 98458 45616</span>
                            </a>
                          </p>
                        </li>
                      </div>
                    </div>
                    <p className="mb-2 d-flex">
                      <a
                        href="mailto:hello@meruwell.com"
                        className="text-white text-decoration-none"
                      >
                        <i className="bi bi-envelope-fill me-3" />
                        <span>hello@meruwell.com</span>
                      </a>
                    </p>
                    <div
                      className={"text-center " + style.socialMediaContainer}
                    >
                      <a
                        href="https://www.facebook.com/profile.php?id=100084289731907"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <i className="bi bi-facebook me-3" />
                      </a>
                      <a href="/">
                        <i className="bi bi-twitter-x me-3" />
                      </a>
                      <a
                        href="https://www.instagram.com/meruwellhealth"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <i className="bi bi-instagram me-3" />
                      </a>
                      <a href="/">
                        <i className="bi bi-linkedin me-3" />
                      </a>
                      <a
                        href="https://www.youtube.com/@meruwell1893"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <i
                          className={`bi bi-youtube me-3 ${style.youtubeIcon}`}
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 mb-5">
                <h5 className="mb-5">
                  Fill the form and our Team will get back to you shortly!
                </h5>
                <Form className={style.formText} onSubmit={formik.handleSubmit}>
                  <Form.Group className="mb-3" controlId="Name">
                    <Form.Control
                      type="text"
                      className={style.formInput}
                      placeholder="Full Name"
                      name="fullName"
                      value={formik.values.fullName}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    {formik.touched.fullName && formik.errors.fullName && (
                      <p className="mt-1 mb-0 text-danger">
                        {formik.errors.fullName}
                      </p>
                    )}
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="Email">
                    <Form.Control
                      type="text"
                      className={style.formInput}
                      placeholder="Email"
                      name="email"
                      value={formik.values.email}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    {formik.touched.email && formik.errors.email && (
                      <p className="mt-1 mb-0 text-danger">
                        {formik.errors.email}
                      </p>
                    )}
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="Mobile">
                    <Form.Control
                      type="tel"
                      className={style.formInput}
                      placeholder="Mobile Number"
                      name="mobile"
                      value={formik.values.mobile}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    {formik.touched.mobile && formik.errors.mobile && (
                      <p className="mt-1 mb-0 text-danger">
                        {formik.errors.mobile}
                      </p>
                    )}
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="message">
                    <Form.Control
                      as="textarea"
                      rows={3}
                      className={`${style.formInput} no-resize`}
                      placeholder="Type your message here..."
                      name="message"
                      value={formik.values.message}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    {formik.touched.message && formik.errors.message && (
                      <p className="mt-1 mb-0 text-danger">
                        {formik.errors.message}
                      </p>
                    )}
                  </Form.Group>
                  <Button
                    type="submit"
                    // onClick={otpInputView ? () => {} : handleSignIn}
                    className={"px-4 " + style.btnClass}
                  >
                    Send message
                  </Button>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default ContactUs;
