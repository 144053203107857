import { httpRequest } from "./httpRequest";
import baseURL from "../utils/urls";

const getCartItemsByUserId = async () => {
  try {
    const response = await httpRequest({
      method: "get",
      baseURL: baseURL,
      url: `/cart`,
    });
    if (response.status >= 200 && response.status < 300) {
      return response.data;
    }
  } catch (err) {
    throw err;
  }
};

const addProductToCart = async (product_id) => {
  try {
    const response = await httpRequest({
      method: "post",
      baseURL: baseURL,
      url: `/cart/add`,
      body: { product_id },
    });
    if (response.status >= 200 && response.status < 300) {
      return response.data;
    }
  } catch (err) {
    throw err;
  }
};

const deleteCartItem = async (cart_id) => {
  try {
    const response = await httpRequest({
      method: "post",
      baseURL: baseURL,
      url: `/cart/delete`,
      body: { cart_id },
    });
    if (response.status >= 200 && response.status < 300) {
      return response.data;
    }
  } catch (err) {
    throw err;
  }
};

const updateCartQuantity = async (data) => {
  try {
    const response = await httpRequest({
      method: "post",
      baseURL: baseURL,
      url: `/cart/update`,
      body: data,
    });
    if (response.status >= 200 && response.status < 300) {
      return response.data;
    }
  } catch (err) {
    throw err;
  }
};

const getUserCartCount = async () => {
  try {
    const response = await httpRequest({
      method: "get",
      baseURL: baseURL,
      url: `/cart/count`,
    });
    if (response.status >= 200 && response.status < 300) {
      return response.data;
    }
  } catch (err) {
    throw err;
  }
};

const getAddress = async () => {
  try {
    const response = await httpRequest({
      method: "get",
      baseURL: baseURL,
      url: `/cart/address`,
    });
    if (response.status >= 200 && response.status < 300) {
      return response.data;
    }
  } catch (err) {
    throw err;
  }
};

const addAddress = async (address) => {
  try {
    const response = await httpRequest({
      method: "post",
      baseURL: baseURL,
      url: `/cart/address/add`,
      body: address,
    });
    if (response.status >= 200 && response.status < 300) {
      return response.data;
    }
  } catch (err) {
    throw err;
  }
};

const updateAddress = async (address) => {
  try {
    const response = await httpRequest({
      method: "post",
      baseURL: baseURL,
      url: `/cart/address/update`,
      body: address,
    });
    if (response.status >= 200 && response.status < 300) {
      return response.data;
    }
  } catch (err) {
    throw err;
  }
};

const getPincodeData = async (pincode, token) => {
  try {
    const response = await httpRequest({
      method: "post",
      baseURL: baseURL,
      url: `/cart/address/pincode`,
      body: { pincode, token },
    });
    if (response.status >= 200 && response.status < 300) {
      return response.data;
    }
  } catch (err) {
    throw err;
  }
};

const orderItems = async () => {
  try {
    const response = await httpRequest({
      method: "get",
      baseURL: baseURL,
      url: `/cart/order`,
    });
    if (response.status >= 200 && response.status < 300) {
      return response.data;
    }
  } catch (err) {
    throw err;
  }
};

const orderItemsWithCash = async () => {
  try {
    const response = await httpRequest({
      method: "post",
      baseURL: baseURL,
      url: `/cart/payWithCash`,
    });
    if (response.status >= 200 && response.status < 300) {
      return response.data;
    }
  } catch (err) {
    throw err;
  }
};

const orderItemsWithInstamojo = async () => {
  try {
    const response = await httpRequest({
      method: "get",
      baseURL: baseURL,
      url: `/cart/payWithInstamojo`,
    });
    if (response.status >= 200 && response.status < 300) {
      return response.data;
    }
  } catch (err) {
    throw err;
  }
};

const getInstamojoPaymentStatus = async (data) => {
  try {
    const response = await httpRequest({
      method: "post",
      baseURL: baseURL,
      url: `/cart/instamojoPaymentStatus`,
      body: data,
    });
    if (response.status >= 200 && response.status < 300) {
      return response.data;
    }
  } catch (err) {
    throw err;
  }
};

const getPaymentStatus = async (paymentId) => {
  try {
    const response = await httpRequest({
      method: "post",
      baseURL: baseURL,
      url: `/cart/paymentStatus`,
      body: { paymentId },
    });
    if (response.status >= 200 && response.status < 300) {
      return response.data;
    }
  } catch (err) {
    throw err;
  }
};

export const cartContoller = {
  getCartItemsByUserId,
  addProductToCart,
  deleteCartItem,
  updateCartQuantity,
  getUserCartCount,
  getAddress,
  addAddress,
  updateAddress,
  getPincodeData,
  orderItems,
  orderItemsWithCash,
  orderItemsWithInstamojo,
  getInstamojoPaymentStatus,
  getPaymentStatus,
};
