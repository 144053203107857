/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useState } from "react";
import { MDBDataTableV5 } from "mdbreact";
import { showNotification } from "../../../../utils/showNotification";
import Loader from "../../../Loader/Loader";
import { useNavigate } from "react-router-dom";
import { adminBlogsController } from "../../../../api/admin/blogs";

function ListBlogs() {
  const history = useNavigate();

  const [loading, setLoading] = useState(false);
  const [blogs, setBlogs] = useState({
    columns: [
      {
        label: "Blog Id",
        field: "id",
      },
      {
        label: "Title",
        field: "title",
      },
      {
        label: "Published On",
        field: "created_at",
      },
      {
        label: "Status",
        field: "status",
      },
      {
        label: "Action",
        field: "action",
      },
    ],
    rows: [],
  });

  const navigateTo = (page, id) => {
    if (page === "add") {
      history("/admin/blogs/add");
    } else if (page === "edit") {
      history(`/admin/blogs/${id}/edit`);
    }
  };

  useEffect(async () => {
    try {
      setLoading(true);
      const result = await adminBlogsController.getAllBlogs();
      if (result.success) {
        setBlogs({
          ...blogs,
          rows: result.blogsList.map((blog) => {
            return {
              ...blog,
              created_at: new Date(blog.created_at).toLocaleDateString("en-GB"),
              action: (
                <>
                  <span
                    className="me-3"
                    onClick={() => {
                      navigateTo("edit", blog.id);
                    }}
                  >
                    <i className="icons bi bi-pencil-square"></i>
                  </span>
                </>
              ),
            };
          }),
        });
      }
    } catch (error) {
      showNotification(error.message, "error");
    } finally {
      setLoading(false);
    }
  }, []);

  return (
    <div className="container mt-5">
      {loading && <Loader />}
      <h2>BLOGS LIST</h2>
      <div className="mt-3 d-flex justify-content-end">
        <button className="btn btn-primary" onClick={() => navigateTo("add")}>
          Add Blog
        </button>
      </div>
      <div className="mt-4">
        {!loading && (
          <MDBDataTableV5
            data={blogs}
            searching={false}
            entriesOptions={[8, 16, 24, 40]}
            entries={8}
            noBottomColumns={true}
            noRecordsFoundLabel={"No blogs Found"}
            responsive
            sortable={false}
          />
        )}
      </div>
    </div>
  );
}

export default ListBlogs;
