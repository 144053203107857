import React from "react";
import Footer from "../Home/Footer";
import About from "../../assets/aboutus.jpg";
import Header from "../Home/Header";
import style from "./AboutUs.module.css";

function AboutUs({setLoginShow}) {
  return (
    <div>
      <Header setLoginShow={setLoginShow}/>
      <div className="container mb-5 mt-3">
        <h1 className={`text-center p-4 fw-bold ${style.title}`}>About Us</h1>
        <div
          className={`d-flex justify-content-center ${style.aboutUsContainer}`}
        >
          <div className="row">
            <div
              className={`col-lg-7 order-2 order-lg-1 d-flex mb-5 ${style.aboutUsLeft}`}
            >
              <div className={style.aboutUs}>
                {/* <p>
                    An Ayurveda, yoga & lifestyle, herbs and home remedies
                    website to disseminate authentic, science backed, ancient
                    healthy living wisdom to the world. Our portal also promotes
                    products and services related to Ayurveda, Yoga and
                    Naturopathy.
                  </p>
                  <p className="mt-5">
                    We help the manufacturers of health-related products to
                    reach the consumers through our portal. While "health of
                    people" is our primary focus, but we are also inclusive
                    about making small & medium enterprises to prosper through
                    MeruWell's portal.
                  </p> */}
                <h5>Welcome to MeruWell - Your Gateway to Holistic Living!</h5>
                <p>
                  At MeruWell, we believe in the power of nature to nurture and
                  heal. Our mission is to empower individuals to embrace
                  holistic lifestyles, incorporating the wisdom of Ayurveda and
                  the purity of natural health products into their daily
                  routines.
                </p>
                <p>
                  With a deep reverence for ancient traditions and a commitment
                  to modern wellness, we curate a diverse range of Ayurvedic and
                  natural health products designed to harmonize mind, body, and
                  spirit. From herbal supplements to skincare essentials, each
                  product is meticulously crafted to support your journey
                  towards vibrant health and vitality.
                </p>
                <br />
                <h5>Why Choose MeruWell?</h5>
                <ol>
                  <li>
                    <b>Authentic Ayurvedic Wisdom</b>: Drawing from
                    centuries-old Ayurvedic principles, our products are
                    formulated using traditional herbs and ingredients known for
                    their potent healing properties. We honor the integrity of
                    these ancient practices while ensuring they align with
                    contemporary lifestyles.
                  </li>
                  <li>
                    <b>Pure and Natural Ingredients</b>: We believe in the power
                    of nature's bounty. That's why all our products are made
                    with carefully sourced, organic, and sustainably harvested
                    ingredients. Free from harmful chemicals and additives, they
                    offer gentle yet effective solutions for holistic
                    well-being.
                  </li>
                  <li>
                    <b>Holistic Wellness Solutions</b>: Whether you're seeking
                    to enhance your physical health, cultivate inner balance, or
                    rejuvenate your skin, we have a solution for you. Our
                    comprehensive range of products addresses diverse wellness
                    needs, promoting holistic vitality from the inside out.
                  </li>
                  <li>
                    <b>Commitment to Quality</b>: Your well-being is our top
                    priority. We adhere to stringent quality standards
                    throughout the manufacturing process to ensure purity,
                    potency, and safety. Each product undergoes rigorous testing
                    and quality control measures to meet our high standards of
                    excellence.
                  </li>
                  <li>
                    <b>Empowering Lifestyle Resources</b>: Beyond products,
                    MeruWell is your trusted partner on the journey to holistic
                    living. Explore our blog for insightful articles, wellness
                    tips, and practical advice from experts in Ayurveda,
                    nutrition, and natural health. We're here to support you
                    every step of the way.
                  </li>
                </ol>
                <br />
                <h5>Join the MeruWell Community:</h5>
                <p>
                  Embark on a transformative journey towards holistic well-being
                  with MeruWell. Discover the power of Ayurveda, embrace the
                  wisdom of nature, and unlock your true potential for vibrant
                  health and vitality. Together, let's cultivate a life of
                  balance, harmony, and wellness.
                </p>
                <br />
                <p>Experience the MeruWell difference today!</p>
              </div>
            </div>
            <div className={`col-lg-5 order-1 order-lg-2 d-flex align-items-start justify-content-center mb-5 ${style.aboutUsImage}`}>
              <img src={About} alt="About us vector" className="img-fluid" />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default AboutUs;
