import "./App.css";
import "react-toastify/dist/ReactToastify.css";
import React, { useEffect, useState } from "react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { ToastContainer } from "react-toastify";
import Navbartop from "./components/Common/NavbarTop/navbar";
import ProductPage from "./components/Products/ProductList/ProductPage";
import ProductDescription from "./components/Products/ProductDescription/ProductDescription";
import LoginModal from "./components/Login/LoginModal";
import Cart from "./components/Cart/Cart";
import AdminLogin from "./components/Admin/Login/AdminLogin";
import AdminNavbar from "./components/Admin/AdminNavbar";
import Address from "./components/Cart/Address/Address";
import Page404 from "./components/Common/Page404/Page404";
import CategoryNavbar from "./components/Common/CategoryNavbar/CategoryNavbar";
import AddProduct from "./components/Admin/Products/AddProduct/AddProduct";
import AdminHome from "./components/Admin/Home/AdminHome";
import ListProduct from "./components/Admin/Products/ListProduct/ListProduct";
import Category from "./components/Admin/Masters/Category/Category";
import CategoryGroup from "./components/Admin/Masters/CategoryGroup/CategoryGroup";
import EditProduct from "./components/Admin/Products/EditProduct/EditProduct";
import HomePage from "./components/Home/HomePage";
import AboutUs from "./components/AboutUs/AboutUs";
import Blogs from "./components/Blogs/Blogs";
import BlogDescription from "./components/Blogs/BlogDescription/BlogDescription";
import ContactUs from "./components/ContactUs/ContactUs";
import ListBlogs from "./components/Admin/Blogs/ListBlogs/ListBlogs";
import AddBlog from "./components/Admin/Blogs/AddBlog/AddBlog";
import EditBlog from "./components/Admin/Blogs/EditBlog/EditBlog";
import Specialization from "./components/Admin/Masters/Specialization/Specialization";
import ListDoctors from "./components/Admin/Doctors/ListDoctors/ListDoctors";
import AddDoctor from "./components/Admin/Doctors/AddDoctor/AddDoctor";
import EditDoctor from "./components/Admin/Doctors/EditDoctor/EditDoctor";
import Doctors from "./components/Doctors/Doctors";
import PrivacyPolicy from "./components/Policies/PrivacyPolicy";
import PlanDetails from "./components/Home/PlanDetails/PlanDetails";
import ListOrders from "./components/Admin/Orders/ListOrders/ListOrders";
import ViewOrder from "./components/Admin/Orders/ViewOrder/ViewOrder";
import Payment from "./components/Cart/Payment/Payment";
import TermsAndConditions from "./components/Policies/TermsAndConditions";
import ReturnAndRefundPolicy from "./components/Policies/ReturnAndRefundPolicy";
import ShippingPolicy from "./components/Policies/ShippingPolicy";
import InstamojoPayment from "./components/Cart/Payment/PaymentWithInstaMojo";
import Promotion from "./components/Products/ProductDescription/Promotion";

function App() {
  const clientId =
    "642492376162-rin8iimvkomn9jkkg6m1gjrvvk4f49ku.apps.googleusercontent.com";

  const [loginShow, setLoginShow] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [page, setPage] = useState("");

  const loginModalComponent = () => {
    return (
      <>
        <Navbartop
          loginShow={loginShow}
          setLoginShow={setLoginShow}
          refresh={refresh}
          setRefresh={setRefresh}
        />
        <CategoryNavbar />
      </>
    );
  };

  const wrapAdminComponent = (component) => {
    return (
      <div className="d-lg-flex">
        <AdminNavbar />
        <div className="px-4 w-100">{component}</div>
      </div>
    );
  };

  useEffect(() => {
    if (!loginShow) {
      setPage("");
    }
  }, [loginShow]);

  return (
    <div>
      <BrowserRouter>
        <GoogleOAuthProvider clientId={clientId}>
          <ToastContainer />
          <Routes>
            {/* Home Page */}
            <Route
              exact
              path="/"
              element={
                <HomePage
                  setLoginShow={setLoginShow}
                  setPage={setPage}
                  refresh={refresh}
                  setRefresh={setRefresh}
                />
              }
            />

            {/* About us page */}
            <Route exact path="/about-us" element={<AboutUs setLoginShow={setLoginShow} />} />

            {/* Privacy Policy page */}
            <Route exact path="/privacy-policy" element={<PrivacyPolicy setLoginShow={setLoginShow} />} />

            {/* Terms and Conditions page */}
            <Route
              exact
              path="/terms-and-conditions"
              element={<TermsAndConditions setLoginShow={setLoginShow} />}
            />

            {/* Return and Refund Policy page */}
            <Route
              exact
              path="/return-and-refund-policy"
              element={<ReturnAndRefundPolicy setLoginShow={setLoginShow} />}
            />

            {/* Shipping Policy page */}
            <Route exact path="/shipping-policy" element={<ShippingPolicy setLoginShow={setLoginShow} />} />

            {/* Contact us page */}
            <Route exact path="/contact-us" element={<ContactUs setLoginShow={setLoginShow} />} />

            {/* Doctors List Page */}
            <Route exact path="/doctors" element={<Doctors setLoginShow={setLoginShow} />} />

            {/* Blogs Home Page */}
            <Route exact path="/blogs" element={<Blogs setLoginShow={setLoginShow} />} />

            {/* Blogs Description Page */}
            <Route exact path="/blogs/:slug" element={<BlogDescription setLoginShow={setLoginShow} />} />

            {/* Plan User Details Page */}
            <Route exact path="/plan/details" element={<PlanDetails />} />

            {/* Products Page */}
            <Route
              exact
              path="/products"
              element={
                <>
                  {loginModalComponent()}
                  <ProductPage />
                </>
              }
            />

            {/* Product Description Page */}
            <Route
              exact
              path="/products/:slug"
              element={
                <>
                  {loginModalComponent()}
                  <ProductDescription
                    refresh={refresh}
                    setRefresh={setRefresh}
                  />
                </>
              }
            />

            {/* Product Promotion Page */}
            <Route
              exact
              path="/festive-offers/:slug"
              element={
                <>
                  {loginModalComponent()}
                  <Promotion refresh={refresh} setRefresh={setRefresh} />
                </>
              }
            />

            {/* Cart Page */}
            <Route
              exact
              path="/cart"
              element={
                <>
                  {loginModalComponent()}
                  <Cart
                    setLoginShow={setLoginShow}
                    refresh={refresh}
                    setRefresh={setRefresh}
                  />
                </>
              }
            />

            {/* Address Page */}
            <Route
              exact
              path="/address"
              element={
                <>
                  {loginModalComponent()}
                  <Address refresh={refresh} setRefresh={setRefresh} />
                </>
              }
            />

            {/* Payment Page */}
            <Route
              exact
              path="/payment"
              element={
                <>
                  <Payment />
                </>
              }
            />

            {/* Instamojo Payment Page */}
            <Route
              exact
              path="/payment-redirect"
              element={
                <>
                  <InstamojoPayment />
                </>
              }
            />

            {/* Admin Login Page */}
            <Route exact path="/admin/login" element={<AdminLogin />} />

            {/* Admin Home Page */}
            <Route
              exact
              path="/admin"
              element={wrapAdminComponent(<AdminHome />)}
            />

            {/* Admin Product List Page */}
            <Route
              exact
              path="/admin/products"
              element={wrapAdminComponent(<ListProduct />)}
            />

            {/* Product Add Page */}
            <Route
              exact
              path="/admin/products/add"
              element={wrapAdminComponent(<AddProduct />)}
            />

            {/* Product Edit Page */}
            <Route
              exact
              path="/admin/products/:id/edit"
              element={wrapAdminComponent(<EditProduct />)}
            />

            {/* Admin Category Page */}
            <Route
              exact
              path="/admin/category"
              element={wrapAdminComponent(<Category />)}
            />

            {/* Admin Category Group Page */}
            <Route
              exact
              path="/admin/categorygroup"
              element={wrapAdminComponent(<CategoryGroup />)}
            />

            {/* Admin Specilization Page */}
            <Route
              exact
              path="/admin/specialization"
              element={wrapAdminComponent(<Specialization />)}
            />

            {/* Admin Doctors Page */}
            <Route
              exact
              path="/admin/doctors"
              element={wrapAdminComponent(<ListDoctors />)}
            />

            {/* Doctor Add Page */}
            <Route
              exact
              path="/admin/doctors/add"
              element={wrapAdminComponent(<AddDoctor />)}
            />

            {/* Doctor Edit Page */}
            <Route
              exact
              path="/admin/doctors/:id/edit"
              element={wrapAdminComponent(<EditDoctor />)}
            />

            {/* Admin Blogs List Page */}
            <Route
              exact
              path="/admin/blogs"
              element={wrapAdminComponent(<ListBlogs />)}
            />

            {/* Blog Add Page */}
            <Route
              exact
              path="/admin/blogs/add"
              element={wrapAdminComponent(<AddBlog />)}
            />

            {/* Blog Edit Page */}
            <Route
              exact
              path="/admin/blogs/:id/edit"
              element={wrapAdminComponent(<EditBlog />)}
            />

            {/* Admin Orders List Page */}
            <Route
              exact
              path="/admin/orders"
              element={wrapAdminComponent(<ListOrders />)}
            />

            {/* Admin Orders View Page */}
            <Route
              exact
              path="/admin/orders/:id"
              element={wrapAdminComponent(<ViewOrder />)}
            />

            {/* Not found */}
            <Route path="/404" element={<Page404 />} />
            <Route path="*" element={ <Navigate to="/404" /> } />
            
          </Routes>
          <LoginModal
            show={loginShow}
            setShow={setLoginShow}
            refresh={refresh}
            setRefresh={setRefresh}
            page={page}
          />
        </GoogleOAuthProvider>
      </BrowserRouter>
    </div>
  );
}

export default App;
