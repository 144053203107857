/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Table } from "react-bootstrap";
import { ordersController } from "../../../../api/admin/orders";
import { showNotification } from "../../../../utils/showNotification";
import Loader from "../../../Loader/Loader";

function ViewOrder() {
  const { id } = useParams();
  const history = useNavigate();
  const [loading, setLoading] = useState(false);
  const [orderData, setOrderData] = useState({});
  const [shippingAddress, setShippingAddress] = useState({});

  const getOrder = async () => {
    try {
      setLoading(true);
      const res = await ordersController.getOrderById(id);
      if (res.success) {
        setOrderData(res.data.items);
        setShippingAddress(res.data.shippingAddress);
      } else {
        showNotification(res.message, "error");
      }
    } catch (err) {
      showNotification(err.message, "error");
    } finally {
      setLoading(false);
    }
  };

  useEffect(async () => {
    getOrder();
  }, [id]);

  return (
    <div className="container my-5">
      {loading && <Loader />}
      <div className="mb-4 d-flex justify-content-between align-items-center">
        <h3>ORDER SUMMARY</h3>
        <button
          type="button"
          className="btn btn-secondary"
          onClick={() => history("/admin/orders")}
        >
          <i className="bi bi-arrow-left me-1" />
          Back
        </button>
      </div>
      {!loading && (
        <div className="px-4">
          <div className="border border-dark p-4">
            <div className="d-flex justify-content-between">
              <div className="d-flex align-items-end">
                <div>
                  <img
                    src="/meruwell-site-logo.png"
                    width="45"
                    height="45"
                    className="d-inline-block align-top me-2"
                    alt="MeruWell logo"
                  />
                  <h4 className="d-inline-block align-bottom mb-0 fw-bold">
                    MeruWell
                  </h4>
                </div>
              </div>
              <div>
                <p className="font-small mb-0">
                  277-B1, Thadagam Main Road, <br />
                  Venkitapuram, Coimbatore, <br />
                  TamilNadu - 641 025
                </p>
              </div>
            </div>

            <hr className="my-4" />

            <div className="row">
              <div className="col-12 col-md-6">
                <div className="h-100 border-end border-secondary">
                  <p>
                    <b>Shipping Address</b>
                  </p>
                  <div className="font-small">
                    <p className="mb-2">
                      <b>{shippingAddress.name}</b>
                    </p>
                    <p>
                      {[shippingAddress.house_no, shippingAddress.street].join(
                        ", "
                      ) + ","}
                      <br />
                      {shippingAddress.landmark ? (
                        <>
                          {shippingAddress.landmark + ","}
                          <br />
                        </>
                      ) : (
                        ""
                      )}
                      {[shippingAddress.city, shippingAddress.state].join(
                        ", "
                      ) +
                        " - " +
                        shippingAddress.pincode}
                      <br />
                      {shippingAddress.country}
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-6">
                <div className="h-100">
                  <p>
                    <b>Billing Address</b>
                  </p>
                  <div className="font-small">
                    <p className="mb-2">
                      <b>{shippingAddress.name}</b>
                    </p>
                    <p>
                      {[shippingAddress.house_no, shippingAddress.street].join(
                        ", "
                      ) + ","}
                      <br />
                      {shippingAddress.landmark ? (
                        <>
                          {shippingAddress.landmark + ","}
                          <br />
                        </>
                      ) : (
                        ""
                      )}
                      {[shippingAddress.city, shippingAddress.state].join(
                        ", "
                      ) +
                        " - " +
                        shippingAddress.pincode}
                      <br />
                      {shippingAddress.country}
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <hr className="mb-5" />

            <div className="d-flex justify-content-between align-items-center mb-5 font-small">
              <p>
                Order Id: <b>{orderData.order_id}</b>
              </p>
              <p>Order Date: {orderData.order_date}</p>
            </div>

            <div className="font-small">
              <Table>
                <thead>
                  <tr>
                    <th>S.No</th>
                    <th>Product</th>
                    <th>Quantity</th>
                    <th>Price</th>
                    <th>GST</th>
                    <th>Net Price</th>
                  </tr>
                </thead>
                <tbody>
                  {orderData.products?.length ? (
                    <>
                      {orderData.products?.map((ord) => {
                        return (
                          <tr key={ord.id}>
                            <td>{ord.no}</td>
                            <td>{ord.product}</td>
                            <td>{ord.quantity}</td>
                            <td>{ord.unit_price}</td>
                            <td>{ord.gst}</td>
                            <td>{ord.total_price}</td>
                          </tr>
                        );
                      })}
                      <tr>
                        <td></td>
                        <th>Delivery Charges</th>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td>
                          {orderData.total_values?.delivery_charges?.toFixed(2)}
                        </td>
                      </tr>
                      <tr>
                        <td></td>
                        <th className="pt-5">Grand Total</th>
                        <th className="pt-5">
                          {orderData.total_values?.quantity}
                        </th>
                        <th className="pt-5">
                          {orderData.total_values?.unit_price?.toFixed(2)}
                        </th>
                        <th className="pt-5">
                          {orderData.total_values?.gst?.toFixed(2)}
                        </th>
                        <th className="pt-5">
                          {Math.round(
                            orderData.total_values?.total_price +
                              orderData.total_values?.delivery_charges
                          )?.toFixed(2)}
                        </th>
                      </tr>
                    </>
                  ) : (
                    <></>
                  )}
                </tbody>
              </Table>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default ViewOrder;
