/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */

import { MDBDataTableV5 } from "mdbreact";
import React, { useState, useEffect } from "react";
import Loader from "../../../Loader/Loader";
import SpecializationModal from "./SpecializationModal";
import { Debounce } from "../../../../utils/debounce";
import { showNotification } from "../../../../utils/showNotification";
import { specializationController } from "../../../../api/admin/specialization";

function Specialization() {
  const [filter, setFilter] = useState("");
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [currData, setCurrData] = useState({});
  const [modalTitle, setModalTitle] = useState("Add");
  const [refresh, setRefresh] = useState(false);
  const [specializationList, setSpecializationList] = useState({
    columns: [
      {
        label: "Spec Id",
        field: "id",
      },
      {
        label: "Specialization",
        field: "specialization_name",
      },
      {
        label: "Action",
        field: "action",
      },
    ],
    rows: [],
  });

  let debouncedFilter = Debounce(filter, 600);

  const handleClose = () => {
    setCurrData({});
    setShowModal(false);
  };

  useEffect(async () => {
    let mounted = true;
    try {
      setLoading(true);
      const res = await specializationController.getAllSpecialization({
        filter: filter,
      });
      if (res.success) {
        const data = res.data.length
          ? res.data.map((spec) => {
              return {
                ...spec,
                action: (
                  <>
                    <span
                      className="me-3 cursor-pointer"
                      onClick={() => {
                        setShowModal(true);
                        setModalTitle("Update");
                        setCurrData(spec);
                      }}
                    >
                      <i className="bi bi-pencil-square"></i>
                    </span>
                    <span
                      onClick={() => {
                        setShowModal(true);
                        setModalTitle("Delete");
                        setCurrData(spec);
                      }}
                      className="cursor-pointer"
                    >
                      <i className="bi bi-trash3 text-danger"></i>
                    </span>
                  </>
                ),
              };
            })
          : [];
        setSpecializationList({ ...specializationList, rows: data });
      } else {
        showNotification(res.message, "error");
      }
    } catch (err) {
      showNotification(err.message, "error");
    } finally {
      setLoading(false);
    }

    return () => {
      mounted = false;
    };
  }, [debouncedFilter, refresh]);

  return (
    <div className="container mt-5">
      <h2>SPECIALIZATION</h2>
      <div className="my-5 d-md-flex justify-content-between">
        <div className="mb-3">
          <input
            type="text"
            className="form-control"
            id="specialization"
            placeholder="Search Specialization"
            value={filter}
            onChange={(e) => setFilter(e.target.value || "")}
            autoComplete="off"
          />
        </div>
        <div className="mb-3 text-end">
          <button
            className="btn btn-primary"
            onClick={() => {
              setShowModal(true);
              setModalTitle("Add");
            }}
          >
            <i className="bi bi-plus-lg me-1"></i>Add
          </button>
        </div>
      </div>
      {loading && <Loader />}
      {!loading && (
        <MDBDataTableV5
          data={specializationList}
          searching={false}
          entriesOptions={[8, 16, 24, 40]}
          entries={8}
          noBottomColumns={true}
          noRecordsFoundLabel={"No Specialization Found"}
          responsive
          sortable={false}
        />
      )}
      <SpecializationModal
        show={showModal}
        handleClose={handleClose}
        title={modalTitle}
        data={currData}
        refresh={refresh}
        setRefresh={setRefresh}
      />
    </div>
  );
}

export default Specialization;
