import axios from "axios";

export const httpRequest = async ({
  method,
  baseURL,
  url,
  body = {},
  contentType = "application/json",
}) => {
  try {
    const response = await axios.request({
      method: method,
      baseURL: baseURL,
      url: url,
      data: body,
      headers: {
        authorization: `Bearer ${localStorage.getItem("meruwell_token")}`,
        "Content-Type": contentType,
      },
      withCredentials: true,
    });

    return response;
  } catch (error) {
    const err = {
      success: false,
      message: error.message || "Internal Server Error, Please try again",
      status: error.status || 500,
    };
    throw err;
  }
};
