/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */

import { MDBDataTableV5 } from "mdbreact";
import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { categoryController } from "../../../../api/admin/category";
import { Debounce } from "../../../../utils/debounce";
import { showNotification } from "../../../../utils/showNotification";
import Loader from "../../../Loader/Loader";
import CategoryGroupModal from "./CategoryGroupModal";

function CategoryGroup() {
  const location = useLocation();
  const query = new URLSearchParams(location.search);

  const [categoryFilter, setCategoryFilter] = useState("");
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [categoryData, setCategoryData] = useState({});
  const [modalTitle, setModalTitle] = useState("Add");
  const [refresh, setRefresh] = useState(false);
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [categoryGroupList, setCategoryGroupList] = useState({
    columns: [
      {
        label: "Group Id",
        field: "id",
      },
      {
        label: "Category Group",
        field: "category_name",
      },
      {
        label: "Action",
        field: "action",
      },
    ],
    rows: [],
  });

  let debouncedCategoryFilter = Debounce(categoryFilter, 600);

  const handleClose = () => {
    setCategoryData({});
    setShowModal(false);
  };

  useEffect(async () => {
    let mounted = true;
    try {
      setLoading(true);
      const res = await categoryController.getAllCategoryGroups({
        categoryFilter: categoryFilter,
      });
      if (res.success) {
        setCategoryOptions(res.categoryData.options);
        const data = res.categoryData.data.length
          ? res.categoryData.data.map((cate) => {
              return {
                ...cate,
                action: (
                  <>
                    <span
                      className="me-3"
                      onClick={() => {
                        setShowModal(true);
                        setModalTitle("Update");
                        setCategoryData({
                          ...cate,
                          category_id: cate.category_id
                            ? cate.category_id.split(",")
                            : [],
                        });
                      }}
                    >
                      <i className="icons bi bi-pencil-square"></i>
                    </span>
                    <span
                      onClick={() => {
                        setShowModal(true);
                        setModalTitle("Delete");
                        setCategoryData({
                          ...cate,
                          category_id: cate.category_id
                            ? cate.category_id.split(",")
                            : [],
                        });
                      }}
                    >
                      <i className="icons bi bi-trash3 text-danger"></i>
                    </span>
                  </>
                ),
              };
            })
          : [];
        setCategoryGroupList({ ...categoryGroupList, rows: data });
      } else {
        showNotification(res.message, "error");
      }
    } catch (err) {
      showNotification(err.message, "error");
    } finally {
      setLoading(false);
    }

    return () => {
      mounted = false;
    };
  }, [debouncedCategoryFilter, refresh]);

  return (
    <div className="container mt-5">
      <h2>CATEGORY GROUPS</h2>
      <div className="my-5 d-md-flex justify-content-between">
        <div className="mb-3">
          <input
            type="text"
            className="form-control"
            id="category"
            placeholder="Search Category Group"
            value={categoryFilter}
            autoComplete="off"
            onChange={(e) => setCategoryFilter(e.target.value || "")}
          />
        </div>
        <div className="mb-3 text-end">
          <button
            className="btn btn-primary"
            onClick={() => {
              setShowModal(true);
              setModalTitle("Add");
            }}
          >
            <i className="bi bi-plus-lg me-1"></i>Add
          </button>
        </div>
      </div>
      {loading && <Loader />}
      {!loading && (
        <MDBDataTableV5
          data={categoryGroupList}
          searching={false}
          entriesOptions={[8, 16, 24, 40]}
          entries={8}
          noBottomColumns={true}
          noRecordsFoundLabel={"No Category Groups Found"}
          responsive
          sortable={false}
        />
      )}
      <CategoryGroupModal
        show={showModal}
        handleClose={handleClose}
        title={modalTitle}
        data={categoryData}
        setData={setCategoryData}
        refresh={refresh}
        setRefresh={setRefresh}
        options={categoryOptions}
      />
    </div>
  );
}

export default CategoryGroup;
