/* eslint-disable react-hooks/exhaustive-deps */
import jwt from "jsonwebtoken";
import React, { useEffect, useState } from "react";
import { Button, Nav, Offcanvas, Tab } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import logo from "../../assets/meruwell-logo.webp";
import { showNotification } from "../../utils/showNotification";
import Loader from "../Loader/Loader";
import style from "./AdminNavbar.module.css";
import Dropdown from "./Common/Dropdown/Dropdown";

function AdminNavbar() {
  const history = useNavigate();
  const location = useLocation();

  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);

  const usersMenuList = [
    { name: "List Users", key: "list-user" },
    { name: "Add User", key: "add-user" },
  ];

  const mastersMenuList = [
    { name: "Category", key: "category" },
    { name: "Category Group", key: "categoryGroup" },
    { name: "Specialization", key: "specialization" },
  ];

  const navigateMapping = {
    home: "/admin",
    listProduct: "/admin/products",
    blogs: "/admin/blogs",
    category: "/admin/category",
    categoryGroup: "/admin/categorygroup",
    specialization: "/admin/specialization",
    doctors: "/admin/doctors",
    orders: "/admin/orders",
  };

  useEffect(() => {
    const token = localStorage.getItem("meruwell_token");
    if (token) {
      const decodeToken = jwt.decode(token);
      if (decodeToken?.isAdmin) {
        if (Date.now() >= decodeToken?.exp) {
          history("/admin/login");
          showNotification("Session Expired!", "error");
          localStorage.removeItem("meruwell_token");
        }
      } else {
        history("/admin/login");
        showNotification(
          "You are not an Admin! Login with correct credentials",
          "error"
        );
      }
    } else {
      history("/admin/login");
      showNotification("Logging out!", "error");
    }
  }, [localStorage.getItem("meruwell_token")]);

  const handleClose = () => {
    setShow(false);
  };

  const toggleShow = () => setShow((sh) => !sh);

  const logout = () => {
    setLoading(true);
    setTimeout(() => {
      localStorage.removeItem("meruwell_token");
      history("/admin/login");
      showNotification("Logged out Successfully!", "success");
      setLoading(false);
    }, 1000);
  };

  return (
    <Tab.Container
      id="tabs"
      activeKey={Object.keys(navigateMapping).find(
        (key) => navigateMapping[key] === location.pathname
      )}
      onSelect={(e) => {
        history(navigateMapping[e]);
        handleClose();
      }}
    >
      {loading && <Loader />}
      <div className={style.sticky}>
        <div className={`d-block d-lg-none ${style.navbarContainer} p-3`}>
          <div className="d-flex justify-content-between">
            <div>
              <img
                alt="logo"
                src={logo}
                width="30"
                height="30"
                className="me-2 d-inline-block align-top"
              />
              <h4 className="text-white d-inline-block align-bottom m-0">
                Meruwell
              </h4>
            </div>
            <div>
              <Button
                onClick={toggleShow}
                className={`p-0 px-2 ${style.menuIcon}`}
              >
                <i className="bi bi-list"></i>
              </Button>
              <Offcanvas
                show={show}
                onHide={handleClose}
                className={style.navbarContainer}
                placement="end"
              >
                <Offcanvas.Header closeButton className="align-items-start">
                  <Nav variant="pills" className="flex-column w-100">
                    <Nav.Item>
                      <Nav.Link className={style.link} eventKey="home">
                        Home
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link className={style.link} eventKey="listProduct">
                        Products
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link className={style.link} eventKey="blogs">
                        Blogs
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link className={style.link} eventKey="orders">
                        Orders
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link className={style.link} eventKey="doctors">
                        Doctors
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Dropdown menuName="Users" menuList={usersMenuList} />
                    </Nav.Item>
                    <Nav.Item>
                      <Dropdown
                        menuName="Master Data"
                        menuList={mastersMenuList}
                      />
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link className={style.link}>
                        <Button
                          variant="outline-light"
                          className="w-100 text-start"
                          onClick={logout}
                        >
                          <i className={`bi bi-power me-1 ${style.logoutIcon}`}></i>
                          Logout
                        </Button>
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>
                </Offcanvas.Header>
              </Offcanvas>
            </div>
          </div>
        </div>
        <div
          className={`d-none d-lg-block ${style.navbarContainer} sticky-top h-100`}
        >
          <div className="d-flex justify-content-center">
            <Nav
              variant="pills"
              className={`flex-column py-3 ${style.navbarItems}`}
            >
              <Nav.Item className="my-4">
                <div>
                  <img
                    alt="logo"
                    src={logo}
                    width="30"
                    height="30"
                    className="me-2 d-inline-block align-top"
                  />
                  <h4 className="text-white d-inline-block align-bottom m-0">
                    Meruwell
                  </h4>
                </div>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link className={style.link} eventKey="home">
                  Home
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link className={style.link} eventKey="listProduct">
                  Products
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link className={style.link} eventKey="blogs">
                  Blogs
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link className={style.link} eventKey="orders">
                  Orders
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link className={style.link} eventKey="doctors">
                  Doctors
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Dropdown menuName="Users" menuList={usersMenuList} />
              </Nav.Item>
              <Nav.Item>
                <Dropdown menuName="Master Data" menuList={mastersMenuList} />
              </Nav.Item>
              <Nav.Item>
                <Nav.Link className={style.link}>
                  <Button
                    variant="outline-light"
                    className="w-100 text-start"
                    onClick={logout}
                  >
                    <i className={`bi bi-power me-1 ${style.logoutIcon}`}></i>
                    Logout
                  </Button>
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </div>
        </div>
      </div>
      {/* <Col md={9} lg={10} className="px-5">
          <Tab.Content>
            <Tab.Pane eventKey="home">
              <AdminHome />
            </Tab.Pane>
            <Tab.Pane eventKey="list-product">
              <ListProduct />
            </Tab.Pane>
            <Tab.Pane eventKey="product-action">
              <AddProduct />
            </Tab.Pane>
            <Tab.Pane eventKey="category">
              <Category />
            </Tab.Pane>
            <Tab.Pane eventKey="category-group">
              <CategoryGroup />
            </Tab.Pane>
          </Tab.Content>
        </Col> */}
      {/* </Row> */}
    </Tab.Container>
  );
}

export default AdminNavbar;
