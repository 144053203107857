/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */

import React, { useEffect, useRef, useState } from "react";
import "./AddProduct.css";
import ReactQuill from "react-quill";
import { adminProductController } from "../../../../api/admin/products";
import { showNotification } from "../../../../utils/showNotification";
import Loader from "../../../Loader/Loader";
import Select from "react-select";
import { categoryController } from "../../../../api/admin/category";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import { useFormik } from "formik";

function AddProduct({ mode, data, refresh }) {
  const initialValue = {
    brand: data?.product_brand || "",
    name: data?.product_name || "",
    caption: data?.caption || "",
    description: data?.product_details || "",
    price: data?.unit_price || "",
    discount: data?.discount || "",
    gst: data?.gst,
    in_stock: +data?.total_quantity - +data?.sold_quantity || "",
    image: [],
    dbImage: data?.product_image ? data.product_image.split(",") : [],
    weight: data?.product_weight || "",
    units: data?.weight_unit || "",
    category: data?.category || "",
    code: data?.product_code || "",
    delivery: data?.delivery_charges || "",
    quantity: "",
    product_type: data?.type || "product",
  };

  const options = [
    { value: "kg", label: "Kilograms" },
    { value: "gm", label: "Grams" },
    { value: "lt", label: "Litres" },
    { value: "ml", label: "Milli Litres" },
    { value: "nos", label: "Nos" },
  ];

  const fileInput = useRef(null);
  const history = useNavigate();

  const [deletedImages, setDeletedImages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [categoryOptions, setCategoryOptions] = useState([]);
  const errorMessage = "*This field is required";

  const SUPPORTED_FORMATS = [
    "image/jpg",
    "image/jpeg",
    "image/png",
    "image/webp",
  ];

  const schema = yup.object({
    brand: yup
      .string()
      .trim()
      .required(errorMessage)
      .max(60, "Limit Exceeded! Maximum Characters: 60"),
    name: yup
      .string()
      .trim()
      .required(errorMessage)
      .max(100, "Limit Exceeded! Maximum Characters: 100"),
    caption: yup
      .string()
      .trim()
      .max(150, "Limit Exceeded! Maximum Characters: 150"),
    description: yup
      .string()
      .test("has text", errorMessage, () => {
        return editorRef.current?.unprivilegedEditor.getLength() > 1;
      })
      .test("max limit", "Limit Exceeded! Maximum Characters: 2500.", () => {
        return editorRef.current?.unprivilegedEditor.getLength() < 2500;
      }),
    price: yup
      .string()
      .trim()
      .required(errorMessage)
      .matches(/^\d*\.?\d+$/, "Please enter only numbers"),
    discount: yup
      .string()
      .trim()
      .matches(/^\d*\.?\d+$/, "Please enter only numbers"),
    gst: yup
      .string()
      .trim()
      .matches(/^\d*\.?\d+$/, "Please enter only numbers"),
    quantity: yup
      .string()
      .trim()
      .matches(/^\d*\.?\d+$/, "Please enter only numbers"),
    image: yup
      .mixed()
      .when("dbImage", (dbImage, schema) => {
        return dbImage.length
          ? schema
          : schema.test("empty", errorMessage, (files) => {
              return Object.keys(files).length > 0;
            });
      })
      .when("dbImage", (dbImage, schema) => {
        return dbImage.length
          ? schema.test(
              "maximum",
              "Limit Exceeded! Maximum Images: 6",
              (files) => {
                return Object.keys(files).length + dbImage.length <= 6;
              }
            )
          : schema.test(
              "maximum",
              "Limit Exceeded! Maximum Images: 6",
              (files) => {
                return Object.keys(files).length <= 6;
              }
            );
      })
      .test("format", "File Format not supported", (files) => {
        return Object.keys(files).every(
          (value) =>
            !files[value] ||
            (files[value] && SUPPORTED_FORMATS.includes(files[value].type))
        );
      }),
    weight: yup
      .string()
      .trim()
      .required(errorMessage)
      .matches(/^\d*\.?\d+$/, "Please enter only numbers"),
    units: yup.string().trim().required(errorMessage),
    category: yup.string().trim().required(errorMessage),
    code: yup
      .string()
      .trim()
      .required(errorMessage)
      .max(100, "Limit Exceeded! Maximum Characters: 100"),
    delivery: yup
      .string()
      .trim()
      .matches(/^\d*\.?\d+$/, "Please enter only numbers"),
  });

  const editorRef = useRef(null);

  const formats = [
    "header",
    "bold",
    "italic",
    "font",
    "underline",
    "strike",
    "blockquote",
    "align",
    "list",
    "bullet",
    "link",
    "size",
    "clean",
  ];

  const modules = {
    toolbar: [
      ["bold", "italic", "underline", "strike"], // toggled buttons
      ["blockquote"],

      [{ list: "ordered" }, { list: "bullet" }],

      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      [{ size: ["small", "Normal", "large", "huge"] }],

      [{ font: [] }],
      [{ align: [] }],

      ["link"],
      ["clean"],
    ],
  };

  const formik = useFormik({
    initialValues: initialValue,
    validationSchema: schema,
    enableReinitialize: true,
    onSubmit: async (values) => {
      try {
        setLoading(true);
        const slug = [
          ...values.name
            .trim()
            .replace(/[^a-zA-Z0-9 ]/g, "")
            .split(" ")
            .filter((word) => word)
            .map((word) => word.toLowerCase()),
        ].join("-");

        const formData = new FormData();
        formData.append("product_brand", values.brand.trim());
        formData.append("product_name", values.name.trim());
        formData.append("product_code", values.code.trim());
        formData.append("caption", values.caption.trim());
        formData.append("product_details", values.description.toString("html"));
        formData.append("unit_price", values.price);
        formData.append("discount", values.discount || 0);
        formData.append("gst", values.gst || 0);
        formData.append("delivery_charges", values.delivery || 0);
        formData.append("total_quantity", values.quantity || 0);
        formData.append("product_weight", values.weight);
        formData.append("weight_unit", values.units);
        formData.append("category", values.category);
        formData.append("product_type", values.product_type);
        formData.append("slug", slug);

        if (values.image?.length !== 0) {
          for (const image of values.image) {
            formData.append("product_image", image);
          }
        }

        if (mode === "edit") {
          if (values.image.length === 0 && values.dbImage.length === 0) {
            formik.setFieldError("image", "*This field is required");
          } else {
            let res;
            if (values.image?.length) {
              formData.append("deleted_image", deletedImages.join(","));
              formData.append("id", data.id);
              formData.append("dbImage", values.dbImage.join(","));
              res = await adminProductController.editProduct(formData, 1);
            } else {
              res = await adminProductController.editProduct({
                ...values,
                quantity: +values.quantity,
                delivery: +values.delivery,
                dbImage: values.dbImage.join(","),
                description: values.description.toString("html"),
                id: data.id,
                deleted_image: deletedImages.join(","),
                slug: slug
              });
            }
            if (res.success) {
              showNotification(res.message, "success");
              refresh();
            } else {
              showNotification(res.message, "error");
            }
          }
        } else {
          const res = await adminProductController.addProduct(formData);
          if (res.success) {
            formik.resetForm();
            showNotification(res.message, "success");
          } else {
            showNotification(res.message, "error");
          }
        }
      } catch (error) {
        throw error;
      } finally {
        setLoading(false);
      }
    },
  });

  const deleteImage = (img) => {
    formik.setFieldValue(
      "dbImage",
      formik.values.dbImage.filter((image) => image !== img)
    );
    setDeletedImages([...deletedImages, img]);
  };

  const handleRadio = (e) => {
    formik.setFieldValue(e.target.name, e.target.id);
  };

  useEffect(async () => {
    let mounted = true;
    setDeletedImages([]);
    fileInput.current.value = null;

    try {
      let categoryOptions = await categoryController.getAllCategories();
      if (categoryOptions.success) {
        setCategoryOptions(categoryOptions.categoryData);
      } else {
        showNotification(categoryOptions.message, "error");
      }
    } catch (err) {
      showNotification(err.message, "error");
    }
    return () => {
      mounted = false;
    };
  }, []);

  useEffect(() => {}, [deletedImages, formik.values]);

  return (
    <div className="container mt-5">
      {loading && <Loader />}
      {!loading && (
        <div className="tab-header">
          <div className="d-flex justify-content-between">
            <h2>{mode !== "edit" ? "ADD" : "EDIT"} PRODUCT</h2>
            <div>
              <button
                type="button"
                className="btn btn-secondary"
                onClick={() => history("/admin/products")}
              >
                <i className="bi bi-arrow-left me-1" />
                Back
              </button>
            </div>
          </div>
          <div className="product-form mt-5">
            <form onSubmit={formik.handleSubmit}>
              <div className="mb-4">
                <label htmlFor="product_type" className="form-label mb-0 me-4">
                  Type
                </label>
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input cursor-pointer"
                    type="radio"
                    name="product_type"
                    id="product"
                    onChange={handleRadio}
                    checked={formik.values.product_type === "product"}
                  />
                  <label className="form-check-label" htmlFor="product">
                    Product
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input cursor-pointer"
                    type="radio"
                    name="product_type"
                    id="promotion"
                    onChange={handleRadio}
                    checked={formik.values.product_type === "promotion"}
                  />
                  <label className="form-check-label" htmlFor="promotion">
                    Promotion
                  </label>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 col-lg-4 mb-4">
                  <label htmlFor="brand" className="form-label">
                    Brand*
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="brand"
                    value={formik.values.brand}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    autoComplete="off"
                  />
                  {formik.touched.brand && formik.errors.brand ? (
                    <p className="mt-1 mb-0 text-danger">
                      {formik.errors.brand}
                    </p>
                  ) : (
                    <></>
                  )}
                </div>
                <div className="col-md-6 col-lg-4 mb-4">
                  <label htmlFor="name" className="form-label">
                    Product Name*
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="name"
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    autoComplete="off"
                  />
                  {formik.touched.name && formik.errors.name ? (
                    <p className="mt-1 mb-0 text-danger">
                      {formik.errors.name}
                    </p>
                  ) : (
                    <></>
                  )}
                </div>
                <div className="col-md-6 col-lg-3 mb-4">
                  <label htmlFor="code" className="form-label">
                    Product Code*
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="code"
                    value={formik.values.code}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    autoComplete="off"
                  />
                  {formik.touched.code && formik.errors.code ? (
                    <p className="mt-1 mb-0 text-danger">
                      {formik.errors.code}
                    </p>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
              <div className="row">
                <div className="col-md-4 mb-4">
                  <label htmlFor="unit" className="form-label">
                    Category*
                  </label>
                  <Select
                    options={categoryOptions}
                    styles={{
                      // Fixes the overlapping problem of the component
                      menu: (provided) => ({ ...provided, zIndex: 999 }),
                    }}
                    value={categoryOptions.filter(
                      (opt) => opt.value === formik.values.category
                    )}
                    name="category"
                    onChange={(e) => {
                      formik.setFieldValue("category", e ? e.value : "");
                    }}
                  />
                  {formik.touched.category && formik.errors.category ? (
                    <p className="mt-1 mb-0 text-danger">
                      {formik.errors.category}
                    </p>
                  ) : (
                    <></>
                  )}
                </div>
                <div className="col-md-8 col-lg-6 col-xl-5 mb-4 row">
                  <div className="col-6">
                    <label htmlFor="weight" className="form-label">
                      Weight*
                    </label>
                    <input
                      className="form-control"
                      type="number"
                      name="weight"
                      min={"1"}
                      step="0.01"
                      value={formik.values.weight}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      autoComplete="off"
                    />
                    {formik.touched.weight && formik.errors.weight ? (
                      <p className="mt-1 mb-0 text-danger">
                        {formik.errors.weight}
                      </p>
                    ) : (
                      <></>
                    )}
                  </div>
                  <div className="col-6">
                    <label htmlFor="unit" className="form-label">
                      Unit*
                    </label>
                    <Select
                      options={options}
                      styles={{
                        // Fixes the overlapping problem of the component
                        menu: (provided) => ({ ...provided, zIndex: 999 }),
                      }}
                      value={options.filter(
                        (opt) => opt.value === formik.values.units
                      )}
                      name="units"
                      onChange={(e) => {
                        formik.setFieldValue("units", e ? e.value : "");
                      }}
                    />
                    {formik.touched.units && formik.errors.units ? (
                      <p className="mt-1 mb-0 text-danger">
                        {formik.errors.units}
                      </p>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12 col-lg-10 mb-4">
                  <label className="form-label">Short Description</label>
                  <textarea
                    className="form-control"
                    name="caption"
                    id="caption"
                    cols="30"
                    rows="3"
                    value={formik.values.caption}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.caption && formik.errors.caption ? (
                    <p className="mt-1 mb-0 text-danger">
                      {formik.errors.caption}
                    </p>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
              <div className="row">
                <div className="col-md-12 col-lg-10 mb-4">
                  <label className="form-label">Description*</label>
                  <ReactQuill
                    theme="snow"
                    ref={editorRef}
                    modules={modules}
                    formats={formats}
                    value={formik.values.description}
                    onChange={(val) => {
                      formik.setFieldValue("description", val);
                    }}
                    onBlur={() => formik.setFieldTouched("description", true)}
                  />
                  {formik.touched.description && formik.errors.description ? (
                    <p className="mt-1 mb-0 text-danger">
                      {formik.errors.description}
                    </p>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
              <div className="row">
                <div className="col-md-5 col-lg-3 mb-4">
                  <label htmlFor="price" className="form-label">
                    Unit Price*
                  </label>
                  <input
                    type="number"
                    step="0.01"
                    className="form-control"
                    name="price"
                    min={"0.01"}
                    value={formik.values.price}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    autoComplete="off"
                  />
                  {formik.touched.price && formik.errors.price ? (
                    <p className="mt-1 mb-0 text-danger">
                      {formik.errors.price}
                    </p>
                  ) : (
                    <></>
                  )}
                </div>
                <div className="col-md-5 col-lg-3 mb-4">
                  <label htmlFor="discount" className="form-label">
                    Discount (in %)
                  </label>
                  <input
                    type="number"
                    step="0.01"
                    className="form-control"
                    name="discount"
                    min={"0"}
                    value={formik.values.discount}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    autoComplete="off"
                  />
                  {formik.touched.discount && formik.errors.discount ? (
                    <p className="mt-1 mb-0 text-danger">
                      {formik.errors.discount}
                    </p>
                  ) : (
                    <></>
                  )}
                </div>
                <div className="col-md-5 col-lg-3 mb-4">
                  <label htmlFor="gst" className="form-label">
                    GST (in %)
                  </label>
                  <input
                    type="number"
                    step="0.01"
                    className="form-control"
                    name="gst"
                    min={"0"}
                    value={formik.values.gst}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    autoComplete="off"
                  />
                  {formik.touched.gst && formik.errors.gst ? (
                    <p className="mt-1 mb-0 text-danger">{formik.errors.gst}</p>
                  ) : (
                    <></>
                  )}
                </div>
                <div className="col-md-5 col-lg-3 mb-4">
                  <label htmlFor="delivery" className="form-label">
                    Delivery Charges (in ₹.)
                  </label>
                  <input
                    type="number"
                    step="0.01"
                    className="form-control"
                    name="delivery"
                    min={"0"}
                    value={formik.values.delivery}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    autoComplete="off"
                  />
                  {formik.touched.delivery && formik.errors.delivery ? (
                    <p className="mt-1 mb-0 text-danger">
                      {formik.errors.delivery}
                    </p>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
              <div className="row">
                <div className="col-md-8 col-lg-6 mb-4">
                  <label htmlFor="image" className="form-label">
                    Product Image*
                  </label>
                  <input
                    // accept=".jpg,.jpeg,.png,.webp"
                    className="form-control"
                    type="file"
                    name="image"
                    ref={fileInput}
                    multiple
                    onChange={(e) => {
                      formik.setFieldTouched("image", true);
                      formik.setFieldValue("image", e.target.files);
                    }}
                  />
                  {formik.touched.image && formik.errors.image ? (
                    <p className="mt-1 mb-0 text-danger">
                      {formik.errors.image}
                    </p>
                  ) : (
                    <></>
                  )}
                  {mode === "edit" && (
                    <>
                      {formik.values.dbImage?.map((img) => {
                        return (
                          <div className="d-flex" key={img}>
                            <p className="mt-3 mb-0">{img}</p>
                            <span
                              className="mt-3 ms-3"
                              onClick={() => deleteImage(img)}
                            >
                              <i className="icons bi bi-trash3 text-danger"></i>
                            </span>
                          </div>
                        );
                      })}
                    </>
                  )}
                </div>
                {mode === "edit" ? (
                  <div className="col-md-8 col-lg-5 mb-4 row">
                    <div className="col-6">
                      <label htmlFor="total_quantity" className="form-label">
                        Quantity in Stock
                      </label>
                      <input
                        type="number"
                        className="form-control"
                        name="total_quantity"
                        value={formik.values.in_stock || ""}
                        disabled={true}
                      />
                    </div>
                    <div className="col-6">
                      <label htmlFor="quantity" className="form-label">
                        Add Quantity
                      </label>
                      <input
                        type="number"
                        className="form-control"
                        name="quantity"
                        min={"0"}
                        value={formik.values.quantity}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        autoComplete="off"
                      />
                      {formik.touched.quantity && formik.errors.quantity ? (
                        <p className="mt-1 mb-0 text-danger">
                          {formik.errors.quantity}
                        </p>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                ) : (
                  <div className="col-md-4 col-lg-3 mb-4">
                    <label htmlFor="quantity" className="form-label">
                      Quantity in Stock
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      name="quantity"
                      min={"0"}
                      value={formik.values.quantity}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      autoComplete="off"
                    />
                    {formik.touched.quantity && formik.errors.quantity ? (
                      <p className="mt-1 mb-0 text-danger">
                        {formik.errors.quantity}
                      </p>
                    ) : (
                      <></>
                    )}
                  </div>
                )}
              </div>
              <button type="submit" className="btn btn-primary my-4">
                {mode === "edit" ? "Update" : "Add"}
              </button>
            </form>
          </div>
        </div>
      )}
    </div>
  );
}

export default AddProduct;
