import { httpRequest } from "../httpRequest";
import baseURL from "../../utils/urls";

const getAllOrders = async () => {
  try {
    const response = await httpRequest({
      method: "post",
      baseURL: baseURL,
      url: `/admin/orders`,
    });
    if (response.status >= 200 && response.status < 300) {
      return response.data;
    }
  } catch (err) {
    throw err;
  }
};

const getOrderById = async (id) => {
  try {
    const response = await httpRequest({
      method: "get",
      baseURL: baseURL,
      url: `/admin/orders/${id}`,
    });
    if (response.status >= 200 && response.status < 300) {
      return response.data;
    }
  } catch (err) {
    throw err;
  }
};

export const ordersController = {
  getAllOrders,
  getOrderById,
};
