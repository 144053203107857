/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */

import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import Loader from "../../Loader/Loader";
import { useFormik } from "formik";
import * as yup from "yup";
import { showNotification } from "../../../utils/showNotification";
import { cartContoller } from "../../../api/cart";

function AddressModal({ show, handleClose, fetchData }) {
  const initialValue = {
    name: "",
    mobile_number: "",
    pincode: "",
    house_no: "",
    street: "",
    landmark: "",
    city: "",
    state: "",
    country: "",
    default_address: false,
  };

  const errorMessage = "*This field is required";
  const [loading, setLoading] = useState(false);

  const schema = yup.object({
    name: yup.string().trim().required(errorMessage),
    mobile_number: yup
      .string()
      .trim()
      .required(errorMessage)
      .matches(/^[0]?[6789]\d{9}$/, "Please enter valid number"),
    pincode: yup
      .string()
      .trim()
      .required(errorMessage)
      .matches(/^[1-9][0-9]{5}$/, "Please enter valid pincode"),
    house_no: yup.string().trim().required(errorMessage),
    street: yup.string().trim().required(errorMessage),
    city: yup.string().trim().required(errorMessage),
    state: yup.string().trim().required(errorMessage),
    country: yup.string().trim().required(errorMessage),
  });

  const formik = useFormik({
    initialValues: initialValue,
    validationSchema: schema,
    enableReinitialize: true,
    onSubmit: async (values) => {
      try {
        setLoading(true);
        const res = await cartContoller.addAddress(values);
        if (res.success) {
          showNotification(res.message, "success");
          handleClose();
          formik.resetForm();
          fetchData();
        } else {
          showNotification(res.message, "error");
        }
      } catch (err) {
        showNotification(err.message, "error");
      } finally {
        setLoading(false);
      }
    },
  });

  const getPincodeData = async (pincode) => {
    try {
      setLoading(true);
      const res = await cartContoller.getPincodeData(
        pincode,
        localStorage.getItem("shiprocket_token")
      );
      if (!res.success) {
        showNotification(res.message, "error");
      } else {
        localStorage.setItem("shiprocket_token", res.data.token);
        formik.setValues({
          ...formik.values,
          city: res.data.city,
          state: res.data.state,
          country: res.data.country,
        });
      }
    } catch (err) {
      showNotification(err.message, "error");
    } finally {
      setLoading(false);
      formik.setFieldValue("pincode", pincode);
    }
  };

  return (
    <Modal
      show={show}
      onHide={() => {
        handleClose();
        formik.resetForm();
      }}
      backdrop="static"
      scrollable={true}
    >
      {loading && <Loader />}
      <Modal.Header closeButton>
        <Modal.Title>Add Address</Modal.Title>
      </Modal.Header>
      <Modal.Body className="mb-4">
        <form>
          <div className="row">
            <div className="mb-4">
              <label htmlFor="name" className="form-label">
                Full Name
              </label>
              <input
                type="text"
                className="form-control"
                name="name"
                autoComplete="off"
                value={formik.values.name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.touched.name && formik.errors.name ? (
                <p className="mt-1 mb-0 text-danger">{formik.errors.name}</p>
              ) : (
                <></>
              )}
            </div>
            <div className="mb-4">
              <label htmlFor="name" className="form-label">
                Mobile Number
              </label>
              <input
                className="form-control"
                type="tel"
                autoComplete="off"
                value={formik.values.mobile_number || ""}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                onKeyDown={(e) => {
                  if (e.key.length === 1) {
                    let ascii = e.key.charCodeAt();
                    if (!(ascii >= 48 && ascii <= 57)) {
                      e.preventDefault();
                    }
                  }
                }}
              />
              {formik.touched.mobile_number && formik.errors.mobile_number ? (
                <p className="mt-1 mb-0 text-danger">
                  {formik.errors.mobile_number}
                </p>
              ) : (
                <></>
              )}
            </div>
            <div className="mb-4">
              <label htmlFor="pincode" className="form-label">
                Pincode
              </label>
              <input
                type="text"
                className="form-control"
                name="pincode"
                autoComplete="off"
                value={formik.values.pincode}
                onBlur={formik.handleBlur}
                onChange={(e) => {
                  let pin = e?.target?.value || "";
                  formik.setFieldValue("pincode", pin);
                  if (pin.length === 6) {
                    getPincodeData(pin);
                  }
                }}
                onKeyDown={(e) => {
                  if (e.key.length === 1) {
                    let ascii = e.key.charCodeAt();
                    if (!(ascii >= 48 && ascii <= 57)) {
                      e.preventDefault();
                    }
                  }
                }}
              />
              {formik.touched.pincode && formik.errors.pincode ? (
                <p className="mt-1 mb-0 text-danger">{formik.errors.pincode}</p>
              ) : (
                <></>
              )}
            </div>
            <div className="mb-4">
              <label htmlFor="house_no" className="form-label">
                Flat, House No., Building, Company, Apartment
              </label>
              <input
                type="text"
                className="form-control"
                name="house_no"
                autoComplete="off"
                value={formik.values.house_no}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.touched.house_no && formik.errors.house_no ? (
                <p className="mt-1 mb-0 text-danger">
                  {formik.errors.house_no}
                </p>
              ) : (
                <></>
              )}
            </div>
            <div className="mb-4">
              <label htmlFor="street" className="form-label">
                Area, Street, Sector, Village
              </label>
              <input
                type="text"
                className="form-control"
                name="street"
                autoComplete="off"
                value={formik.values.street}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.touched.street && formik.errors.street ? (
                <p className="mt-1 mb-0 text-danger">{formik.errors.street}</p>
              ) : (
                <></>
              )}
            </div>
            <div className="mb-4">
              <label htmlFor="landmark" className="form-label">
                Landmark
              </label>
              <input
                type="text"
                className="form-control"
                name="landmark"
                autoComplete="off"
                value={formik.values.landmark}
                onChange={formik.handleChange}
              />
            </div>
            <div className="mb-4">
              <label htmlFor="city" className="form-label">
                Town/City
              </label>
              <input
                type="text"
                className="form-control"
                name="city"
                autoComplete="off"
                value={formik.values.city}
                onBlur={formik.handleBlur}
              />
              {formik.touched.city && formik.errors.city ? (
                <p className="mt-1 mb-0 text-danger">{formik.errors.city}</p>
              ) : (
                <></>
              )}
            </div>
            <div className="mb-4">
              <label htmlFor="state" className="form-label">
                State
              </label>
              <input
                type="text"
                className="form-control"
                name="state"
                autoComplete="off"
                value={formik.values.state}
                onBlur={formik.handleBlur}
              />
              {formik.touched.state && formik.errors.state ? (
                <p className="mt-1 mb-0 text-danger">{formik.errors.state}</p>
              ) : (
                <></>
              )}
            </div>
            <div className="mb-4">
              <label htmlFor="country" className="form-label">
                Country
              </label>
              <input
                type="text"
                className="form-control"
                name="country"
                autoComplete="off"
                value={formik.values.country}
                onBlur={formik.handleBlur}
              />
              {formik.touched.country && formik.errors.country ? (
                <p className="mt-1 mb-0 text-danger">{formik.errors.country}</p>
              ) : (
                <></>
              )}
            </div>
            <div>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  checked={formik.values.default_address ? true : false}
                  name="default_address"
                  onChange={(e) => {
                    formik.setFieldValue(
                      "default_address",
                      e?.target?.checked || false
                    );
                  }}
                />
                <label className="form-check-label" htmlFor="default_address">
                  Set as Default Address
                </label>
              </div>
            </div>
          </div>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <button
          type="button"
          className="btn btn-primary"
          onClick={formik.handleSubmit}
        >
          Add
        </button>
      </Modal.Footer>
    </Modal>
  );
}

export default AddressModal;
