/*eslint-disable react-hooks/exhaustive-deps*/

import React, { useEffect, useState } from "react";
import { cartContoller } from "../../../api/cart";
import { showNotification } from "../../../utils/showNotification";
import Loader from "../../Loader/Loader";
import "../Cart.css";
import AddressModal from "./AddressModal";
import { useNavigate, useLocation } from "react-router-dom";
import PaymentModeModal from "./PaymentModeModal";

function Address({ refresh, setRefresh }) {
  const [show, setShow] = useState(false);
  const [payShow, setPayShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [addresses, setAddresses] = useState([]);
  const [defaultAddress, setDefaultAddress] = useState({});
  const [currentAddress, setCurrentAddress] = useState({});
  const [modalTitle, setModalTitle] = useState("Add Modal");
  const history = useNavigate();
  const location = useLocation();

  const handleClose = () => {
    setShow(false);
  };

  const fetchData = async () => {
    try {
      setLoading(true);
      const res = await cartContoller.getAddress();
      if (res) {
        if (res.data?.length) {
          const defAddr = res.data?.find((d) => d.default_address);
          setDefaultAddress(defAddr || {});
          setAddresses(res.data?.filter((d) => !d.default_address));
        }
      } else {
        showNotification(res.message, "error");
      }
    } catch (err) {
      showNotification(err.message, "error");
    } finally {
      setLoading(false);
    }
  };

  const setAsDefaultAddress = async (address_id) => {
    try {
      setLoading(true);
      const res = await cartContoller.updateAddress({
        id: address_id,
        action: "setDefault",
      });
      if (res) {
        showNotification("Default Address has been set", "success");
        fetchData();
      } else {
        showNotification(res.message, "error");
      }
    } catch (err) {
      showNotification(err.message, "error");
    } finally {
      setLoading(false);
    }
  };

  const deleteAddress = async (address_id) => {
    try {
      setLoading(true);
      const res = await cartContoller.updateAddress({
        id: address_id,
        action: "delete",
      });
      if (res) {
        fetchData();
      } else {
        showNotification(res.message, "error");
      }
    } catch (err) {
      showNotification(err.message, "error");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (location.state === "cart") {
      fetchData();
    } else {
      history("/404", { replace: true });
    }
  }, []);

  return (
    <div className="container cart-container">
      <div className="d-md-flex justify-content-md-between">
        <h2 className="mb-3">Add Delivery Address</h2>
        <div className="d-flex justify-content-end">
          <button
            className="mb-3 btn btn-primary"
            onClick={() => {
              setCurrentAddress({});
              setShow(true);
              setModalTitle("Add Address");
            }}
          >
            Add New Address
          </button>
        </div>
      </div>

      {loading && <Loader />}

      {!Object.keys(defaultAddress).length && !addresses.length ? (
        <div className="d-flex justify-content-center align-items-center emptyContainer">
          <div>
            <h3>No Address been added yet!</h3>
          </div>
        </div>
      ) : (
        !Object.keys(defaultAddress).length && (
          <div
            className="mt-3 alert alert-warning alert-dismissible fade show"
            role="alert"
          >
            <strong>Please set a Default Address to proceed further!</strong>
            <button
              type="button"
              className="btn-close me-3"
              data-bs-dismiss="alert"
              aria-label="Close"
            ></button>
          </div>
        )
      )}

      <div className="row mt-5">
        {Object.keys(defaultAddress).length ? (
          <div className="col-12 col-md-6 col-lg-4 mb-5">
            <div className="card addressCard">
              <div className="card-header">Default Delivery Address</div>
              <div className="card-body">
                <div className="card-text address-container">
                  <h6 className="mb-3">
                    <b>{defaultAddress?.name}</b>
                  </h6>
                  <p>
                    {defaultAddress?.house_no}, {defaultAddress?.street},
                  </p>
                  <p>{defaultAddress?.landmark}</p>
                  <p>
                    {defaultAddress?.city}, {defaultAddress?.state} -{" "}
                    {defaultAddress?.pincode},
                  </p>
                  <p>{defaultAddress?.country}.</p>
                  <p>Phone number : {defaultAddress?.mobile_number}</p>
                </div>
              </div>
              <div className="card-footer d-flex justify-content-end">
                <p
                  className="text-primary mb-0 cursor-pointer"
                  onClick={() => {
                    setCurrentAddress(defaultAddress);
                    setShow(true);
                    setModalTitle("Update Address");
                  }}
                >
                  Edit
                </p>
                <span className="mx-2">|</span>
                <p
                  className="text-danger me-2 mb-0 cursor-pointer"
                  onClick={() => {
                    deleteAddress(defaultAddress?.id);
                  }}
                >
                  Remove
                </p>
              </div>
            </div>
          </div>
        ) : (
          <></>
        )}

        {addresses?.map((addr, idx) => {
          return (
            <div className="col-12 col-md-6 col-lg-4 mb-5" key={idx}>
              <div className="card addressCard" onClick={() => {}}>
                <div className="card-body mt-3">
                  <div className="card-text address-container">
                    <h6 className="mb-3">
                      <b>{addr?.name}</b>
                    </h6>
                    <p>
                      {addr?.house_no}, {addr?.street},
                    </p>
                    <p>{addr?.landmark}</p>
                    <p>
                      {addr?.city}, {addr?.state} - {addr?.pincode},
                    </p>
                    <p>{addr?.country}.</p>
                    <p>Phone number : {addr?.mobile_number}</p>
                  </div>
                </div>
                <div className="card-footer d-flex justify-content-end align-items-end">
                  <p
                    className="text-primary mb-0 cursor-pointer"
                    onClick={() => {
                      setCurrentAddress(addr);
                      setShow(true);
                      setModalTitle("Update Address");
                    }}
                  >
                    Edit
                  </p>
                  <span className="mx-2">|</span>
                  <p
                    className="text-danger mb-0 cursor-pointer"
                    onClick={() => {
                      deleteAddress(addr?.id);
                    }}
                  >
                    Remove
                  </p>
                  <span className="mx-2">|</span>
                  <p
                    className="text-secondary mb-0 me-2 cursor-pointer"
                    onClick={() => setAsDefaultAddress(addr?.id)}
                  >
                    Set as Default
                  </p>
                </div>
              </div>
            </div>
          );
        })}
      </div>

      <div className="my-4 d-flex justify-content-end">
        <div>
          <button
            type="button"
            className="btn btn-primary mx-2"
            onClick={() => history("/cart", { replace: true })}
          >
            <i className="bi bi-arrow-left me-2"></i>Back to Cart
          </button>
          <button
            type="button"
            className="btn btn-primary ms-2"
            onClick={() => setPayShow(true)}
            disabled={Object.keys(defaultAddress).length === 0}
          >
            Proceed to Payment
          </button>
        </div>
      </div>

      <AddressModal
        show={show}
        handleClose={handleClose}
        modalTitle={modalTitle}
        data={currentAddress}
        fetchData={fetchData}
      />

      <PaymentModeModal
        show={payShow}
        handleClose={() => setPayShow(false)}
        refresh={refresh}
        setRefresh={setRefresh}
      />
    </div>
  );
}

export default Address;
