/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */

import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { adminDoctorsController } from "../../../../api/admin/doctors";
import { showNotification } from "../../../../utils/showNotification";
import Loader from "../../../Loader/Loader";
import AddDoctor from "../AddDoctor/AddDoctor";

function EditDoctor() {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [editData, setEditData] = useState();

  const getProduct = async () => {
    try {
      setLoading(true);
      const res = await adminDoctorsController.getDoctorById(id);
      if (res.success) {
        setEditData(res.data);
      } else {
        showNotification(res.message, "error");
      }
    } catch (err) {
      showNotification(err.message, "error");
    } finally {
      setLoading(false);
    }
  };

  useEffect(async () => {
    let mounted = true;
    getProduct();
    return () => {
      mounted = false;
    };
  }, [id]);

  return (
    <>
      {loading && <Loader />}
      {!loading && (
        <AddDoctor mode={"edit"} data={editData} refresh={getProduct} />
      )}
    </>
  );
}

export default EditDoctor;
